import { useAtom } from "jotai";
import { isTransferModalOpenAtom } from "../store/modal";
import React, { useContext, useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { useAccount } from "wagmi";
import axios, { AxiosResponse } from "axios";
import { AppContext } from "../context/AppProvider";
import { http, createConfig } from "@wagmi/core";
import { readContract } from "@wagmi/core";
import { arbitrum } from "@wagmi/core/chains";
import { Principal } from "@dfinity/principal";
import uniswapLogo from "../assets/image/uniswaplogo.png";
export default function Mint() {
  const [isTransferModalOpen, setModalOpen] = useAtom(isTransferModalOpenAtom);
  const [ethBalance, setEthbalance] = useState(0);
  const [eyesAmount, setEyesAmount] = useState();
  const [xPerEyes, setXPerEyes] = useState(1);
  const [xdragonAmount, setXdragonAmount] = useState(0);
  const [isMinting, setIsMinting] = useState(false);
  const [mintResult, setMintResult] = useState(0);
  const [hash, setHash] = useState("");
  const [arbiscan, setArbiscan] = useState("");
  const [lastUserMint, setLastUserMint] = useState(0);
  const [lastMinted, setLastMinted] = useState(0);
  const [userCoolDown, setUserCoolDown] = useState(0);
  const [xEth, setXEth] = useState(1);
  const [tm, setTm] = useState("");
  const [totalBurnt, setTotalBurnt] = useState(0);
  const [nextBurn, setNextBurn] = useState(0);

  function getTimeDifference(timestamp) {
    const millisecondsPerMinute = 60 * 1000;
    const millisecondsPerHour = 60 * millisecondsPerMinute;
    const millisecondsPerDay = 24 * millisecondsPerHour;

    const currentTime = new Date().getTime();
    const difference = currentTime - timestamp;
    if (timestamp == 0) return " - ";

    if (difference < millisecondsPerMinute) {
      return `${Math.floor(difference / 1000)} seconds ago`;
    } else if (difference < millisecondsPerHour) {
      return `${Math.floor(difference / millisecondsPerMinute)} minutes ago`;
    } else if (difference < millisecondsPerDay) {
      return `${Math.floor(difference / millisecondsPerHour)} hours ago`;
    } else {
      return `${Math.floor(difference / millisecondsPerDay)} days ago`;
    }
  }

  function truncateString(str, num) {
    if (!str) return "";
    if (str.length <= num) {
      return str;
    }
    const frontChars = Math.ceil(num / 2);
    const backChars = Math.floor(num / 2);
    return (
      str.substr(0, frontChars) + "..." + str.substr(str.length - backChars)
    );
  }

  function copyHashToClipboard() {
    //const textToCopy = walletAddress; // Replace with your actual string variable
    const textToCopy = hash;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        alert("tx hash copied to clipboard");
      })
      .catch((err) => {
        console.error("Error in copying text: ", err);
      });
  }

  const {
    eyesBalance,
    isGenesis,
    code,
    canisterActor, //xdragon
    walletAddress,
    eyesLedger,
    changeAddress,
    setRequestUpdateEyes,
    setPXB,
    pxb,
    mintingData,
    setMintingData,
  } = useContext(AppContext);

  const acc_ = useAccount();

  const [ethformatted, setEthformatted] = useState(0);

  const convertedEyesBalance = Number(eyesBalance) / 100000000;

  // minimum 100
  // maximum same as eyesBalance
  var isValidAmountToMint =
    eyesAmount >= 100 && eyesAmount <= convertedEyesBalance;

  // console.log(isValidAmountToMint, "<<<<<< wadiaw");

  async function handleMint() {
    setMintResult(0);
    setHash("");
    setArbiscan("");
    try {
      setIsMinting(true);

      //console.log(walletAddress, "<<<<<<<waellet");
      var acc = {
        owner: Principal.fromText(walletAddress),
        subaccount: [],
      };

      var XDragonSmartContract = {
        owner: Principal.fromText(process.env.REACT_APP_CANISTER_ID),
        subaccount: [],
      };

      var approve_ = {
        fee: [],
        memo: [],
        from_subaccount: [],
        created_at_time: [],
        amount: eyesAmount * 100000000,
        expected_allowance: [],
        expires_at: [],
        spender: XDragonSmartContract,
      };

      var approval = await eyesLedger.icrc2_approve(approve_);

      console.log("minting..");
      var a = await canisterActor.mintXDRAGON(eyesAmount * 100000000, xPerEyes); //times 10**8 decimals for EYES
      console.log(a, "<<<<<< mint result");

      if (a.success) {
        setMintResult(1);
        setArbiscan("https://arbiscan.io/tx/" + a.success);
        setHash(a.success.toString());
      } else if (a.error) {
        setMintResult(2);
        setHash(a.error);
      } else if (a.timeout) {
        setMintResult(1);
        setArbiscan("https://arbiscan.io/tx/" + a.timeout);
        setHash(a.timeout);
      }
      setIsMinting(false);
      setRequestUpdateEyes(true);
      setEyesAmount(0);
      setXdragonAmount(0);
    } catch (e) {
      setIsMinting(false);
      console.log(e, "<<<<<err minting");
    }
  }

  function copyToClipboard() {
    const textToCopy = code;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        alert("Code copied to clipboard");
      })
      .catch((err) => {
        console.error("Error in copying text: ", err);
      });
  }

  const shareText = `Mint XDragon — The 1st Hybrid ERC20/721 using HybridX standard with onchain gamblification

use my invite code: ${code}
  
Mint page: https://x.dragoneyes.xyz `;

  const handleShare = () => {
    const tweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      shareText
    )}`;
    window.open(tweetUrl, "_blank");
  };

  var first = true;

  useEffect(() => {
    async function getXPrice() {
      try {
        //console.log("calling.. ");
        //var price = await canisterActor.getEyesXDRAGON();
        var mintDatas = await canisterActor.getMintingData();

        // console.log(mintDatas, "<<<<<<<<<<< md");
        //console.log(price, "<<<<< xdragon price to eyes");
        setXPerEyes(Number(mintDatas.xPrice));
        setLastMinted(Number(mintDatas.lastMint));
        setLastUserMint(Number(mintDatas.lastUserMint));
        setTotalBurnt(Number(mintDatas.burnt));
        setNextBurn(Number(mintDatas.nextBurn));
        if (mintDatas.coolDown > Date.now())
          setUserCoolDown(Number(mintDatas.coolDown));
        else setUserCoolDown(0);
        setXEth(Number(mintDatas.xEth));
        setTm(getTimeDifference(Number(mintDatas.lastMint)));
        //console.log(Number(mintDatas.coolDown) > 0, "siasd<<<<");
      } catch (e) {
        console.log(e, "<<<< er md");
      }

      try {
        const abi = [
          {
            type: "function",
            name: "balanceOf",
            stateMutability: "view",
            inputs: [{ name: "account", type: "address" }],
            outputs: [{ type: "uint256" }],
          },
          {
            type: "function",
            name: "totalSupply",
            stateMutability: "view",
            inputs: [],
            outputs: [{ name: "supply", type: "uint256" }],
          },
        ];

        const config = createConfig({
          chains: [arbitrum],
          transports: {
            [arbitrum.id]: http(),
          },
        });

        const result = await readContract(config, {
          abi,
          address: process.env.REACT_APP_XDRAGON_ERC20_CONTRACT,
          functionName: "balanceOf",
          args: ["0xF4951699b480893f65bFeAA986321B0e44165602"],
          account: "0xF4951699b480893f65bFeAA986321B0e44165602",
        });
        //console.log(Number(result), "<<<<nr");
        setPXB(Number(result));
        axios
          .get(`https://api.dragoneyes.xyz/xdreth`, {
            withCredentials: false,
          })
          .then((v) => {
            //if (v.data) console.log(v.data, "<<<get");
          });
      } catch (n) {
        console.log(n, "pxberr");
      }
    }
    if (first) {
      first = false;
      getXPrice();
    }

    const intervalId = setInterval(() => {
      // Call your function here
      getXPrice();
    }, 15000); // 15 seconds in milliseconds

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [canisterActor]);

  const calculateTimeLeft = () => {
    const difference = userCoolDown - Date.now();

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        minutes: Math.floor((difference / (1000 * 60)) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = {
        minutes: 0,
        seconds: 0,
      };
    }

    return timeLeft;
  };

  function getNextUTCMidnightTimestamp() {
    // Get the current date and time in UTC
    const now = new Date();

    // Create a new Date object for the next midnight in UTC
    const nextMidnight = new Date(
      Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + 1)
    );

    // Return the timestamp in milliseconds
    return nextMidnight.getTime();
  }

  const calculateBurnTimeLeft = () => {
    const difference = nextBurn - Date.now();

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / (1000 * 60)) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = {
        minutes: 0,
        seconds: 0,
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [burnTimeLeft, setBurnTimeLeft] = useState(calculateBurnTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
      setBurnTimeLeft(calculateBurnTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <div className="relative z-50 w-full">
      {/* Overlay */}

      <div className="flex items-center justify-center w-full  inset-0 overflow-y-auto ">
        <div className=" w-full items-center justify-center text-center">
          <div className="w-full max-w-md transform overflow-hidden rounded-b-2xl bg-warm-white p-0 mt-0 text-left align-middle shadow-xl transition-all relative">
            {/*!changeAddress ? (
              <>
                <div className="pt-6 w-full flex text-xl lg:text-2xl px-10 font-passion leading-6 text-gray-900 items-center justify-between text-left">
                  <div className="px-2 text-left">
                    {isGenesis ? "Your genesis code" : "Your invite code"}
                  </div>{" "}
                  <div>
                    <span
                      onClick={() => {
                        copyToClipboard();
                      }}
                      className="cursor-pointer px-2 text-[#0753c4]"
                    >
                      {code.toString()}📋
                    </span>
                  </div>
                </div>
                <div className="flex w-full pb-6 text-xl lg:text-2xl px-10 font-passion leading-6 text-gray-900 items-center justify-between text-left">
                  <div className="px-2 text-left">$EYES</div>{" "}
                  <div>
                    <span className="cursor-pointer px-2">
                      👀{" "}
                      {parseFloat(
                        Number(eyesBalance) / 100000000
                      ).toLocaleString("id-ID")}
                    </span>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="flex w-full pb-6 text-xl lg:text-2xl px-10 font-passion leading-6 text-gray-900 items-center justify-center align-middle text-center">
                  <div className="px-2 py-5 text-left">
                    address changed
                    <br />
                    Loading data from ICP...
                  </div>{" "}
                </div>
              </>
            )}

            <div className="text-center grid w-full lg:text-xl text-sm border border-[#9c9891] border-l-0 border-r-0">
              <div className="text-sm px-10 text-dark-blue">
                <div className="pt-5 font-passion text-xl">
                  Get more $EYES by sharing your invitation code
                  <br />
                  <span className="text-base">
                    +1000 $EYES each user using your code to buy ticket or mint
                    XDragon
                  </span>
                </div>
                <div className="w-full grid  bg-dashboard-blue p-2 border bg-dark-blue border-[#1E3557] bg-opacity-90 rounded-2xl my-5 ">
                  <div className="text-center grid w-full lg:text-lg font-passion text-sm">
                    <div
                      className="flex w-full text-center justify-center items-center cursor-pointer text-warm-white text-3xl"
                      onClick={() => handleShare()}
                    >
                      Share
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {xPerEyes == 1 ? (
              <div className=" pt-6 w-full flex text-xl lg:text-2xl px-10 font-passion leading-6 text-gray-900 items-center justify-between text-left">
                <div className="px-2 text-left">Loading Data...</div>{" "}
              </div>
            ) : (
              <></>
            )}
            <div className="">
              {
                <div className=" pt-6 w-full flex text-xl lg:text-2xl px-10 font-passion leading-6 text-gray-900 items-center justify-between text-left">
                  <div className="px-2 w-full grid text-center items-center justify-center align-middle h-[350px] text-bright-red">
                    <div className="text-center font-passero  h-full  w-full text-4xl">
                      XDRAGON HAS BEEN MINTED OUT!
                    </div>
                    <div className="flex text-white bg-[#1E3557] py-1.5 px-4 lg:py-2.5 lg:px-4 rounded-xl">
                      <a
                        href="https://app.uniswap.org/swap?exactField=input&exactAmount=10&outputCurrency=0x27800A3453e2583953b50Fbd7F9Dd4d220f7e594&chain=arbitrum"
                        target="blank"
                        className="flex gap-2 items-center"
                      >
                        <img
                          src={uniswapLogo}
                          width={22}
                          alt="uniswap"
                          className="object-cover"
                        />
                        <span>Buy XDRAGON on Uniswap</span>
                      </a>
                    </div>
                    <div className="text-center font-passero  h-full  w-full text-2xl"></div>
                  </div>{" "}
                </div>
              }

              {/*<div className=" pt-6 w-full flex text-xl lg:text-2xl px-10 font-passion leading-6 text-gray-900 items-center justify-between text-left">
                <div className="px-2 text-left"></div>{" "}
              </div>
              <>
                {" "}
                <div className=" pt-1 w-full flex text-sm lg:text-lg px-10 font-passion leading-6 text-gray-900 items-center justify-between text-left">
                  <div className="px-2 text-left">
                    Last XDRAGON Minted :{" "}
                    <span className="text-green-600">{tm}</span>
                  </div>{" "}
                </div>
                <div className=" pt-1 w-full flex text-sm lg:text-lg px-10 font-passion leading-6 text-gray-900 items-center justify-between text-left">
                  <div className="px-2 text-left">
                    XDR/EYES :{" "}
                    <span className="text-green-600">
                      {(xPerEyes / 100000000).toLocaleString()}
                    </span>
                  </div>{" "}
                  <div className="px-2 text-left">
                    XDR/ETH :{" "}
                    <span className="text-green-600">
                      {(xEth / 10 ** 18).toFixed(2).toLocaleString()}
                    </span>
                  </div>{" "}
                </div>
              </>
              <div className="pt-2 w-full flex text-xl lg:text-2xl px-10 font-passion leading-6 text-gray-900 items-center justify-between text-left">
                <div className="py-2 px-2 border border-[#1e3557] bg-white rounded-2xl w-full flex text-xl lg:text-2xl font-passion  text-gray-900 items-center justify-between text-left">
                  <div>
                    <NumericFormat
                      className="w-4/5 p-2 rounded-xl focus:outline-none number-input-container bg-white text-2xl lg:text-3xl  text-[#1E3557] text-left "
                      value={eyesAmount}
                      isAllowed={() => !isMinting} //disabling input when minting
                      onValueChange={({ floatValue }) => {
                        // update xdragon receive value
                        if (floatValue) {
                          var fl = floatValue;
                          if (fl * 100000000 > eyesBalance) fl = eyesBalance;
                          console.log(fl, "<<<f;");
                          console.log(eyesBalance, "eb");
                          setEyesAmount(fl);
                          var xdra_ = ((fl * 100000000) / xPerEyes).toFixed(5);
                          if (xdra_ > 5 || fl > xPerEyes * 5) {
                            if (eyesBalance > xPerEyes * 5) {
                              setEyesAmount((xPerEyes * 5) / 100000000);
                              xdra_ = 5;
                            } else {
                              setEyesAmount(eyesBalance / 100000000);
                              setXdragonAmount(xdra_);
                            }
                          } else {
                            setEyesAmount(fl);
                            setXdragonAmount(xdra_);
                          }
                          isValidAmountToMint =
                            fl >= 100 && fl <= eyesBalance / 100000000;
                          //console.log(isValidAmountToMint, "vall");
                          //console.log(pxb, "<<<pxsetpxb");
                        }
                      }}
                    />
                  </div>
                  <div
                    onClick={() => {
                      if (isMinting) return; //disabling when minting

                      setEyesAmount(Number(eyesBalance) / 100000000);
                      var xdra_ = (Number(eyesBalance) / xPerEyes).toFixed(5);
                      if (xdra_ > 5) {
                        setEyesAmount((xPerEyes * 5) / 100000000);
                        xdra_ = 5;
                        setXdragonAmount(xdra_);
                      }
                      setXdragonAmount(xdra_);
                    }}
                    className="bg-bright-red rounded-lg p-4 py-2 text-white text-center"
                  >
                    max
                  </div>
                </div>
              </div>

              {/* info text 
              <span
                className={`pl-10 pt-2 ${
                  !isValidAmountToMint && eyesAmount
                    ? "text-rose-700"
                    : "text-[#475467]"
                }`}
              >
                {!isValidAmountToMint && eyesAmount
                  ? `Minimum 100 and maximum ${(
                      (xPerEyes * 5) /
                      100000000
                    ).toLocaleString("id-ID")} `
                  : "Enter your $EYES amount"}
              </span>

              <div className="px-10 mt-5">
                <div className="text-[#1E3557] bg-[#d2c8a3] rounded-xl p-5 flex justify-between text-xl lg:text-2xl font-passion">
                  <span>{xdragonAmount.toString()}</span>
                  <span>XDRAGON</span>
                </div>
              </div>
              <div className="px-10 mt-0">
                <div className=" text-green-800 px-2  rounded-xl py-0 flex justify-between text-sm lg:text-lg font-passion">
                  <span>
                    {parseFloat(pxb / 10 ** 18)
                      .toFixed(2)
                      .toLocaleString()}{" "}
                    available to mint on Phase 2<br />
                  </span>
                </div>
                <div className="text-bright-red px-2  rounded-xl py-0 flex justify-between text-sm lg:text-sm font-passion">
                  <span>
                    Burning 0.5% XDRAGON in{" "}
                    {`${burnTimeLeft.hours} hrs, ${burnTimeLeft.minutes} mins, ${burnTimeLeft.seconds} secs`}
                    <br />
                  </span>
                </div>
                <div className="text-bright-red px-2  rounded-xl py-0 flex justify-between text-sm lg:text-sm font-passion">
                  <span>
                    Total burnt : {totalBurnt}
                    <br />
                  </span>
                </div>
              </div>

              <div className="px-10 pt-5 pb-5">
                {userCoolDown == 0 ? (
                  <button
                    className={`w-full p-3 rounded-lg text-3xl font-black text-white font-passion ${
                      !isValidAmountToMint || pxb / 10 ** 18 < xdragonAmount
                        ? "bg-stone-300"
                        : "bg-bright-red"
                    }`}
                    onClick={() => {
                      if (isMinting) return; //disabling when minting

                      handleMint();
                    }}
                    disabled={
                      !isValidAmountToMint || pxb / 10 ** 18 < xdragonAmount
                    }
                  >
                    {isMinting ? "MINTING..." : "MINT"}
                  </button>
                ) : (
                  <h2 className="text-green-600 w-full text-center">
                    Cooling period until{" "}
                    {`${timeLeft.minutes} minutes ${timeLeft.seconds} seconds`}
                  </h2>
                )}
              </div>
              <div className="px-10 pt-2 pb-2 text-center w-full justify-center items-center text-lg text-bright-red">
                {mintResult == 1 ? (
                  <div className="grid text-[#0753c4]">
                    <h4>XDRAGON successfully minted!</h4>
                    <div className="flex w-full text-center justify-center items-center">
                      <a href={arbiscan}>hash : {truncateString(hash, 9)} </a>
                      <span
                        onClick={() => {
                          copyHashToClipboard();
                        }}
                        className="cursor-pointer px-2 text-[#0753c4]"
                      >
                        📋
                      </span>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {mintResult == 2 ? (
                  <div>
                    Mint failed
                    <br />
                    error : {hash}
                  </div>
                ) : (
                  <></>
                )}
              </div>*/}
            </div>
            <div className="mt-4"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
