import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useEffect } from "react";
import { useSignMessage } from "wagmi";
import { recoverMessageAddress } from "viem";
import React, { useContext, useState } from "react";
import { AppContext } from "../context/AppProvider";
import { useAccount } from "wagmi";
export const CustomConnectSmall = () => {
  const ethers = require("ethers");
  const {
    data: signMessageData,
    error,
    isLoading,
    signMessage,
    variables,
  } = useSignMessage();
  const {
    setCorrectChain,
    signed,
    signature,
    setSignature,
    correctChain,
    walletAddress,
    setSigned,
  } = useContext(AppContext);
  const acc_ = useAccount();

  useEffect(() => {
    (async () => {
      if (variables?.message && signMessageData) {
        const recoveredAddress = await recoverMessageAddress({
          message: variables?.message,
          signature: signMessageData,
        });
        //console.log(recoveredAddress);
        setSignature(signMessageData);
        setSigned(true);
      }
    })();
    //console.log(signMessageData, "<<<<<<<<<<< smd");
  }, [signMessageData, variables?.message]);

  async function signTypedData(from) {
    var add = acc_.address;
    const msg = "Welcome to XDRAGON! Sign to enter dapps";

    try {
      // console.log("signing....");
      const aa = signMessage({
        message: msg,
      });
    } catch (error) {
      console.error("Error signing message:", error);
      //setSigning(false);
      throw error;
    }
  }

  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted && authenticationStatus !== "loading";
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === "authenticated");
        //if (chain.unsupported) setCorrectChain(!chain.unsupported);
        return (
          <div
            {...(!ready && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <button
                    onClick={openConnectModal}
                    className="w-full  text-2xl  lg:hidden  px-6 py-3 font-passion text-warm-white rounded-lg bg-[#EE5151] "
                  >
                    Connect Wallet
                  </button>
                );
              }
              if (connected && !signed) {
                return (
                  <button
                    onClick={async () => {
                      var signature = await signTypedData(acc_.address);
                      return;
                    }}
                    className="w-full  text-2xl  lg:hidden  px-6 py-3 font-passion text-warm-white rounded-lg bg-[#EE5151] "
                  >
                    SIGN TO ENTER
                  </button>
                );
              }
              if (chain.unsupported) {
                return (
                  <button
                    onClick={openChainModal}
                    className="w-full  text-2xl  lg:hidden  px-6 py-3 font-passion text-warm-white rounded-lg bg-[#EE5151] "
                  >
                    Wrong network
                  </button>
                );
              }
              return (
                <div className="w-full flex gap-2 justify-center items-center">
                  <button
                    onClick={openChainModal}
                    style={{ display: "flex", alignItems: "center" }}
                    type="button"
                  >
                    {"Loading Data..."}
                  </button>
                  <button onClick={openAccountModal} type="button">
                    {account.displayName}
                    {account.displayBalance
                      ? ` (${account.displayBalance})`
                      : ""}
                  </button>
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};
