import React, { createContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { ConfigProvider } from "antd";

import OpenLogin from "@toruslabs/openlogin";

import { actorCreation, getUserPrincipal } from "../service/icdragoncanister";
import { eyesCreation } from "../service/eyesledgercanister";
import { icpAgent as icpAgentCreation } from "../service/icpledgercanister";

import smallIcon from "../assets/favico.ico";

export const AppContext = createContext({});

const openLoginConfig = {
  clientId: process.env.REACT_APP_OPEN_LOGIN_CLIENT_ID,
  network: process.env.REACT_APP_OPEN_LOGIN_NETWORK,
  uxMode: "popup",
};

const openLoginConfigOld = {
  clientId: process.env.REACT_APP_OPEN_LOGIN_CLIENT_ID,
  network: process.env.REACT_APP_OPEN_LOGIN_NETWORK,
  uxMode: "popup",
};
const themeProvider = {
  token: {
    colorPalette1: "#79d5c6",
    fontFamily: "FamiljenGrotesk",
    Button: {
      colorPrimary: "linear-gradient(90deg, #112cbccc, #8d2895cc) !important",
      fontWeight: 600,
      primaryColor: "white",
      border: "border: 1px solid #7F56D9",
      borderColorDisabled: "border: 1px solid #7F56D9",
    },
  },
  components: {
    Layout: {
      headerBg: "#152233",
      siderBg: "#152233",
    },
  },
};

export const AppProvider = ({ children }) => {
  const [openlogin, setSdk] = useState();
  const [canisterActor, setCanisterActor] = useState();
  const [walletAddress, setWalletAddress] = useState(false);
  const [eyesLedger, setEyesLedger] = useState(false);
  const [eyesBalance, setEyesBalance] = useState(false);
  const [toggleMobileMenu, setToggleMobileMenu] = useState(false);
  const [ethconnected, setEthconnected] = useState(false);
  const [ethaddress, setEthaddress] = useState(false);
  const [correctChain, setCorrectChain] = useState(true);
  const [ethVault, setEthVault] = useState(false);
  const [code, setCode] = useState(false);
  const [isGenesis, setIsGenesis] = useState(false);
  const [changeAddress, setChangeAddress] = useState(false);
  const [requestUpdateEyes, setRequestUpdateEyes] = useState(false);
  const [pxb, setPXB] = useState(0);
  const [refreshXData, setRefreshXData] = useState(false);
  const [apr, setApr] = useState(0);
  const [yesterdayFee, setYesterdayFee] = useState(0);
  const [totalFees, setTotalFees] = useState(0);
  const [xtoEth, setXtoEth] = useState(0);
  const [xPot, setXPot] = useState(0);
  const [lastPotWinner, setLastPotWinner] = useState("");
  const [gasFee, setGasFee] = useState(0);
  const [win, setWin] = useState(false);
  const [gameRes, setGameRes] = useState(false);
  const [signed, setSigned] = useState(false);
  const [signature, setSignature] = useState(false);
  const [mintingData, setMintingData] = useState(false);

  useEffect(() => {
    async function initializeOpenlogin() {
      const sdkInstance = new OpenLogin(openLoginConfig);
      await sdkInstance.init();
      setSdk(sdkInstance);
      if (sdkInstance?.privKey) {
        let privKey = sdkInstance?.privKey;
        const eyes_ = eyesCreation(privKey);
        var principalString_ = getUserPrincipal(privKey).toString();
        setEyesLedger(eyes_);
        setWalletAddress(principalString_);
      }
    }
    initializeOpenlogin();
  }, []);

  return (
    <AppContext.Provider
      value={{
        loginInstance: openlogin,
        canisterActor,
        setCanisterActor,
        walletAddress,
        setWalletAddress,
        setEyesLedger,
        eyesLedger,
        eyesBalance,
        setEyesBalance,
        toggleMobileMenu,
        setToggleMobileMenu,
        ethaddress,
        setEthaddress,
        ethconnected,
        setEthconnected,
        setCorrectChain,
        correctChain,
        setEthVault,
        ethVault,
        setIsGenesis,
        isGenesis,
        setCode,
        code,
        setChangeAddress,
        changeAddress,
        requestUpdateEyes,
        setRequestUpdateEyes,
        setPXB,
        pxb,
        setRefreshXData,
        refreshXData,
        apr,
        setApr,
        yesterdayFee,
        setYesterdayFee,
        totalFees,
        setTotalFees,
        xtoEth,
        setXtoEth,
        lastPotWinner,
        setLastPotWinner,
        xPot,
        setXPot,
        gasFee,
        setGasFee,
        win,
        setWin,
        setGameRes,
        gameRes,
        signed,
        setSigned,
        signature,
        setSignature,
        mintingData,
        setMintingData,
      }}
    >
      <Helmet>
        <title>XDragon</title>
        <link rel="icon" type="png" href={smallIcon} />
        <link rel="apple-touch-icon" type="png" href={smallIcon} />
      </Helmet>
      <ConfigProvider theme={themeProvider}>{children}</ConfigProvider>
    </AppContext.Provider>
  );
};

export default AppProvider;
