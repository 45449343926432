import React, { useState, useMemo, useEffect, useContext } from "react";
import { useMediaQuery } from "react-responsive";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { AppContext } from "../../../../context/AppProvider";
import DragAbleItem from "./DragAbleItem";
import DropArea from "./DropArea";
import { listTokensOfOwner as listTokensOfOwnerANKR } from "./utils/list_tokens_of_owner_ankr";
import { listTokensOfOwner as listTokensOfOwnerCHAINBASE } from "./utils/list_tokens_of_owner_chainbase";
import ERC20HX from "../../abi/ERC20HXFeesOracle.json";
import ERC721PsiHX from "../../abi/ERC721PsiHX.json";
import {
  useReadContracts,
  useAccount,
  useBlockNumber,
  usePublicClient,
  useChainId,
  useWaitForTransactionReceipt,
  useWriteContract,
  useConfig,
} from "wagmi";
import { formatUnits, parseUnits } from "viem";

import { readContract } from "@wagmi/core";
import axios from "axios";

const XDRAGON_ERC20_CONTRACT = process.env.REACT_APP_XDRAGON_ERC20_CONTRACT;
const XDRAGON_ERC721_CONTRACT = process.env.REACT_APP_XDRAGON_ERC721_CONTRACT;

// Captures 0x + 4 characters, then the last 4 characters.
const truncateRegex = /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/;

/**
 * Truncates an ethereum address to the format 0x0000…0000
 * @param address Full address to truncate
 * @returns Truncated address
 */
const truncateEthHex = (address) => {
  const match = address.match(truncateRegex);
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

const listTokensOfOwner = async (account, contractAddress, chainId = 1) => {
  try {
    return await listTokensOfOwnerCHAINBASE(account, contractAddress, chainId);
  } catch (e) {
    console.log(e);

    // fallback to ankr
    return await listTokensOfOwnerANKR(account, contractAddress, chainId);
  }
};

const sleep = (ms) => new Promise((res) => setTimeout(res, ms));

const Murn = () => {
  const {
    setRefreshXData, //xdragon
  } = useContext(AppContext);
  const { address, isConnecting, isDisconnected } = useAccount();
  const [lastTxHash, setLastTxHash] = useState();
  const blockNumber = useBlockNumber({
    watch: true,
  });
  const chainId = useChainId();
  const config = useConfig();
  const [userNFTs, setUserNFTs] = useState([]);

  const { writeContractAsync } = useWriteContract();

  const waitLastTx = useWaitForTransactionReceipt({
    hash: lastTxHash,
    confirmations: 2,
  });

  const erc20ContractReader = useReadContracts({
    contracts: [
      {
        abi: ERC20HX.abi,
        address: XDRAGON_ERC20_CONTRACT,
        functionName: `fee`,
        args: [1],
      },
    ],
  });

  const [selectedNft, setSelectedNft] = useState([]);
  const [toMurnNfts, setToMurnNfts] = useState([]);
  const [murnFees, setMurnFees] = useState(0);
  const [isReadyToLoadImage, setIsReadyToLoadImage] = useState(false);

  const onNFTFetch = useEffect(() => {
    if (blockNumber.data) {
      listTokensOfOwner(address, XDRAGON_ERC721_CONTRACT, chainId).then((x) => {
        x.forEach(async (nftId, index) => {
          console.log(JSON.stringify(nftId) + "<<< x");
          const tokenURI = await readContract(config, {
            abi: ERC721PsiHX.abi,
            address: XDRAGON_ERC721_CONTRACT,
            functionName: `tokenURI`,
            args: [nftId],
          });

          if (selectedNft.findIndex((nft) => nft.id === nftId) === -1) {
            const data = {
              src: null,
              alt: "NFT ID " + nftId,
              id: nftId,
              isSelected: false,
              tokenURI: tokenURI,
            };

            // let newSelectedNFT = ;
            // newSelectedNFT.push(data);
            setSelectedNft((selectedNft) => [...selectedNft, data]);
          }
        });
      });
    }
  }, [chainId && !blockNumber.isLoading]);

  const onNFTLoaded = useEffect(() => {
    const currentSelectedNft = [...selectedNft];
    console.log(currentSelectedNft + "<< xyz");
    currentSelectedNft.forEach(async (item, index) => {
      if (item.src === null) {
        await sleep(650 * index);
        console.log("updating " + item.id);
        const metadata = (await axios.get(item.tokenURI)).data;
        item.src = metadata["image"];
      }
    });
  }, [selectedNft]);

  const imageList = [
    // {
    //   src: "https://i.ibb.co/FhHszV4/2024-03-02-12-26-23.jpg",
    //   alt: "NFT 1",
    //   id: "nft0001",
    //   isSelected: false,
    // },
    // {
    //   src: "https://i.ibb.co/FhHszV4/2024-03-02-12-26-23.jpg",
    //   alt: "NFT 2",
    //   id: "nft0002",
    //   isSelected: false,
    // },
    // {
    //   src: "https://i.ibb.co/FhHszV4/2024-03-02-12-26-23.jpg",
    //   alt: "NFT 3",
    //   id: "nft0003",
    //   isSelected: false,
    // },
    // {
    //   src: "https://i.ibb.co/FhHszV4/2024-03-02-12-26-23.jpg",
    //   alt: "NFT 4",
    //   id: "nft0004",
    //   isSelected: false,
    // },
    // {
    //   src: "https://i.ibb.co/FhHszV4/2024-03-02-12-26-23.jpg",
    //   alt: "NFT 5",
    //   id: "nft0005",
    //   isSelected: false,
    // },
  ];
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const handleMurn = async () => {
    // loop selectetNFT check isSelected and submit
    console.log("handle murn");

    try {
      const toMurnIds = toMurnNfts.map((item) => item.id);
      const txHash = await writeContractAsync({
        address: XDRAGON_ERC20_CONTRACT,
        abi: ERC20HX.abi,
        functionName: `burnNFT`,
        args: [toMurnIds], // eslint-disable-line
        value: murnFees,
      });

      setLastTxHash(txHash);
      setRefreshXData(true);
    } catch (e) {
      console.log(e);
    }
  };

  const handleSelectNft = (id, status) => {
    const currentSelectedNft = [...selectedNft];
    const nftIndex = currentSelectedNft.findIndex((nft) => nft.id === id);

    currentSelectedNft[nftIndex].isSelected = status;
    setSelectedNft(currentSelectedNft);

    setToMurnNfts(currentSelectedNft.filter((item) => item.isSelected));
  };

  const isAnyNftSelected = useMemo(() => {
    return selectedNft.some((item) => item.isSelected);
  }, [selectedNft]);

  const onSuccessReadContracts = useEffect(() => {
    if (
      toMurnNfts.length &&
      erc20ContractReader.data &&
      erc20ContractReader.data[0]?.status === "success"
    )
      setMurnFees(
        (erc20ContractReader.data[0].result *
          BigInt(toMurnNfts.length) * // eslint-disable-line
          125n) /
          100n
      );
  }, [
    !erc20ContractReader.isLoading &&
      erc20ContractReader.isSuccess &&
      erc20ContractReader.data,
    selectedNft.map((item) => item.isSelected),
  ]);

  const onMurnComplete = useEffect(() => {
    if (waitLastTx.isSuccess) {
      const toMurnIds = toMurnNfts.map((item) => item.id);
      const currentSelectedNft = [...selectedNft];
      const newSelectedNft = currentSelectedNft.filter(
        (item) => !toMurnIds.includes(item.id)
      );
      setSelectedNft(newSelectedNft);
      setToMurnNfts([]);
    }
  }, [!waitLastTx.isLoading && waitLastTx.isSuccess]);

  return (
    <div className="w-full bg-[#DFDAC9] rounded-md min-h-60 p-5">
      <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
        <div>
          {/* nft list section */}
          <div style={{ overflow: "hidden", clear: "both" }}>
            <span className="text-md font-bold text-[#1E3557] py-1">
              My NFTs ({selectedNft.length.toString()})
            </span>
            <div className="flex overflow-x-auto py-2 text-black">
              {selectedNft.length == 0 ? "" : ""}
              {selectedNft.map((nft, index) => {
                if (nft.isSelected) {
                  return null;
                } else {
                  return (
                    <div
                      key={index}
                      className={`w-24 h-24  flex-none ${
                        index !== selectedNft.length - 1 ? "mr-4" : ""
                      }`}
                    >
                      {nft.src == null ? (
                        <div className="animate-pulse">
                          ⌛ Loading metadata...
                        </div>
                      ) : (
                        <DragAbleItem nft={nft} onSelectNft={handleSelectNft} />
                      )}
                    </div>
                  );
                }
              })}
            </div>
          </div>

          <div style={{ overflow: "hidden", clear: "both", marginTop: "30px" }}>
            <span className="text-md font-bold text-[#1E3557] py-1 mb-2">
              Drag NFT here to Murn
            </span>
            <DropArea
              selectedNft={selectedNft}
              onSelectNft={handleSelectNft}
              isMurning={waitLastTx.isLoading}
            />
          </div>
          <label className="text-black">
            Total Murn Fees: {parseFloat(formatUnits(murnFees, 18)).toFixed(6)}
          </label>

          {/* murn button */}
          <div className="roll-button w-full py-5">
            <button
              className={`w-full p-3 rounded-lg text-3xl font-black text-white font-passion ${
                isAnyNftSelected ? "bg-[#0754C4]" : "bg-[#7E91AE]"
              }`}
              onClick={handleMurn}
              disabled={!isAnyNftSelected}
            >
              Murn
            </button>
            <span className="text-black">
              {lastTxHash ? (
                waitLastTx.isLoading ? (
                  <div className="animate-pulse">
                    `⌛ Processing, please wait...`
                  </div>
                ) : (
                  `✅ Last txHash: ${truncateEthHex(lastTxHash)}`
                )
              ) : (
                ""
              )}
            </span>
          </div>
        </div>
      </DndProvider>
    </div>
  );
};

export default Murn;
