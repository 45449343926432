import React, { useState, useContext, useEffect } from "react";
import { useAtom } from "jotai";
import Bint from "./components/Bint";
import Murn from "./components/Murn";
import DiceRoller from "../DiceRoller";
import { AppContext } from "../../context/AppProvider";
import dice from "../../assets/dice.png";
import ethIcon from "../../assets/eth-icon.png";
import { isGameMessageModalOpenAtom } from "../../store/modal";
const getRandomRoll = () => {
  const min = Math.ceil(1);
  const max = Math.floor(6);
  const dice1 = Math.floor(Math.random() * (max - min + 1)) + min;
  const dice2 = Math.floor(Math.random() * (max - min + 1)) + min;
  return [dice1, dice2];
};

const XDragon = () => {
  const {
    canisterActor,
    walletAddress,
    refreshXData,
    lastPotWinner,
    setGameRes,
    setWin,
    setRefreshXData, //xdragon
  } = useContext(AppContext);
  const [mode, setMode] = useState("roll");
  const [onRoll, setOnroll] = useState(false);
  const [potBalance, setPotBalance] = useState(0);
  const [rollResult, setRollResult] = useState(getRandomRoll());
  const [remainingTicket, setRemainingTicket] = useState(0);
  const [gameMsg, setGameMsg] = useState("");
  const [rewardNumber, setRewardNumber] = useState(0);
  const rewardAmount = "0";
  const [allowRoll, setAllowRoll] = useState(false);
  const [isGameModalOpen, setGameModalOpen] = useAtom(
    isGameMessageModalOpenAtom
  );
  const [isRolling, setIsRolling] = useState(false);
  const handleRollButton = async () => {
    // return;
    //
    // return;
    if (!allowRoll) return;
    setOnroll(true); // start rolling dice
    setGameMsg("");
    const rollResult = await canisterActor.roll_dice();
    // means roll result not [1,1] (dragon eyes)
    console.log(rollResult, "<<<<< roll result");

    setGameModalOpen(true);
    setGameRes(true);

    if (rollResult.lose) {
      setWin(
        "You Lose " +
          Number(rollResult.lose[0]) +
          " and " +
          Number(rollResult.lose[1])
      );
      setRollResult([rollResult.lose[0], rollResult.lose[1]]);
    } else if (rollResult.win) {
      setWin("YOU WIN!");
      setRollResult([1, 1]);
    } else if (rollResult.noroll) {
      setWin("Rolling failed, you can try again");
    } else if (rollResult.noticket) {
      setWin("You have no ticket");
    } else {
      setWin(false);
    }

    setOnroll(false); // stop rolling dice
    await getRemainingTicket();
  };

  const getRemainingTicket = async () => {
    //console.log(canisterActor, "<<<getting waddre");
    setAllowRoll(false);
    try {
      const res = await canisterActor.getRemainingRollTicket();
      setRemainingTicket(Number(res) || 0);
      const pot = await canisterActor.getPotETHBalance();
      console.log(pot, "<<<ptt");
      if (Number(pot) <= 0) setAllowRoll(false);
      else {
        setAllowRoll(true);
      }
      setRewardNumber(Number(pot));
      setPotBalance(
        parseFloat(Number(pot) / 10 ** 18)
          .toFixed(5)
          .toLocaleString()
      );

      setRefreshXData(false);
    } catch (e) {
      setRefreshXData(false);
    }
  };
  function truncateString(str, num) {
    if (!str) return "";
    if (str.length <= num) {
      return str;
    }
    const frontChars = Math.ceil(num / 2);
    const backChars = Math.floor(num / 2);
    return (
      str.substr(0, frontChars) + "..." + str.substr(str.length - backChars)
    );
  }
  useEffect(() => {
    async function cc() {
      await getRemainingTicket();
    }
    if (canisterActor || refreshXData) {
      cc();
    }
    console.log("canister changed " + walletAddress);
  }, [canisterActor, refreshXData]);

  return (
    <>
      {/* game section */}

      <div
        disabled="true"
        className=" w-full max-w-md transform overflow-hidden rounded-2xl rounded-t-none min-h-96 bg-warm-white p-0 text-left align-middle  transition-all relative"
      >
        {/* toogle section */}
        <div className="toogle-button  w-full flex justify-beetween pt-6 pr-5 pl-5 pb-2 gap-5">
          <button
            className={`w-1/2 p-2 rounded-lg border-2 border-[#D2C8A3] text-2xl  font-passion ${
              mode === "roll"
                ? "bg-[#D2C8A3] text-[#1E3557]"
                : " text-warm-white bg-[#D6D3D1]"
            }`}
            onClick={() => {
              setMode("roll");
              getRemainingTicket();
            }}
          >
            🎲ROLL!
          </button>
          <button
            className={`w-1/2 p-2 rounded-lg border-2 border-[#D2C8A3] text-2xl   font-passion ${
              mode === "bint"
                ? "bg-[#D2C8A3] text-[#1E3557]"
                : " text-warm-white bg-[#D6D3D1]"
            }`}
            onClick={() => setMode("bint")}
          >
            Bint
          </button>

          <button
            className={`w-1/2 p-2 rounded-lg border-2 border-[#D2C8A3] text-2xl   font-passion ${
              mode === "murn"
                ? "bg-[#D2C8A3] text-[#1E3557]"
                : " text-warm-white bg-[#D6D3D1]"
            }`}
            onClick={() => setMode("murn")}
          >
            Murn
          </button>
        </div>

        {/* component render section */}
        <div className="component-render  w-full pr-5 pl-5 pb-5">
          {mode === "bint" ? <Bint /> : <></>}
          {mode === "murn" ? <Murn /> : <></>}
          {mode === "roll" ? (
            <div
              disabled="true"
              className=" w-full max-w-md transform overflow-hidden rounded-2xl min-h-96 bg-[#DFDAC9] p-7 mt-0 text-left align-middle  transition-all relative"
            >
              <div className=" font-passero text-lg text-bright-red text-center items-center w-full justify-center pb-4">
                last pot winner :{" "}
                <div className="text-dark-blue">
                  {truncateString(lastPotWinner, 14)}
                </div>
              </div>
              <div className="dice-image  w-full flex justify-center py-0 pb-0">
                <DiceRoller onRoll={onRoll} result={rollResult} />
              </div>

              {/* reward section */}
              <div className="reward-section w-full flex bg-warm-white justify-between rounded-xl py-1">
                <span className="text-4xl px-3 font-black text-[#EE5151] font-passion">
                  WIN
                </span>
                {/* reward section */}
                <div className="reward-amount w-8/12 flex justify-between items-center bg-warm-white p-3 rounded-xl">
                  <span className="text-3xl font-black text-[#1E3557] font-passion">
                    {potBalance}
                  </span>
                  <div className="eth-icon flex justify-between p-2  rounded-lg gap-3 px-2">
                    <span className="text-lg font-black text-dark-blue font-passion">
                      ETH
                    </span>
                    <img src={ethIcon} alt="wrong network" width={27} />
                  </div>
                </div>
              </div>
              <div className="reward-section w-full flex justify-center text-center items-center py-3">
                <span className="text-base font-black text-dark-blue  font-passero">
                  you win dragon pot by getting dragon eyes (1-1)
                </span>
                {/* reward section */}
              </div>

              {/* button roll */}
              <div className="roll-button w-full py-4">
                {rewardNumber / 10 ** 18 > 0.001 ? (
                  !onRoll ? (
                    <button
                      // disabled={remainingTicket === 0}
                      className={`w-full p-3 rounded-xl text-3xl font-black text-white font-passion ${
                        remainingTicket > 0 ? "bg-[#EE5151]" : "bg-stone-300"
                      }`}
                      onClick={handleRollButton}
                    >
                      ROLL
                    </button>
                  ) : (
                    <button
                      disabled="true"
                      className={`w-full p-3 rounded-xl text-3xl font-black text-white font-passion ${
                        remainingTicket > 0 ? "bg-[#EE5151]" : "bg-stone-300"
                      }`}
                    >
                      ROLLING....
                    </button>
                  )
                ) : (
                  <button
                    className={`w-full p-3 rounded-xl text-3xl font-black text-white font-passion ${
                      remainingTicket > 0 ? "bg-stone-300" : "bg-stone-300"
                    }`}
                  >
                    ROLL
                  </button>
                )}
              </div>

              {/* remaining */}
              <div className="reward-section w-full flex justify-between py-2">
                <span className="text-3xl font-black text-[#1E3557] font-passion">
                  Remaining ticket
                </span>
                <span className="text-3xl font-black text-[#1E3557] font-passion">
                  {remainingTicket}
                </span>
              </div>

              {/* info section */}
              <div className="reward-section w-full flex justify-between">
                <div className=" w-full py-2 border-2 border-[#1E3557] text-center rounded-lg">
                  <span className="text-md font-black text-[#1E3557] py-1">
                    Get more ticket by binting minimum of 1 NFT
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default XDragon;
