import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useEffect } from "react";
import { useSignMessage } from "wagmi";
import { recoverMessageAddress } from "viem";
//import { signMessage } from "@wagmi/core";
import React, { useContext, useState } from "react";
import { AppContext } from "../context/AppProvider";
import { useAccount } from "wagmi";
export const CustomConnect = () => {
  const {
    data: signMessageData,
    error,
    isLoading,
    signMessage,
    variables,
  } = useSignMessage();

  const {
    setCorrectChain,
    signed,
    signature,
    setSignature,
    correctChain,
    walletAddress,
    setSigned,
  } = useContext(AppContext);
  const acc_ = useAccount();

  const recoveredAddress = React.useRef();

  useEffect(() => {
    (async () => {
      if (variables?.message && signMessageData) {
        const recoveredAddress = await recoverMessageAddress({
          message: variables?.message,
          signature: signMessageData,
        });
        //console.log(recoveredAddress);
        setSignature(signMessageData);
        setSigned(true);
      }
    })();
    //console.log(signMessageData, "<<<<<<<<<<< smd");
  }, [signMessageData, variables?.message]);

  async function signTypedData(from) {
    var add = acc_.address;
    const msg = "Welcome to XDRAGON! Sign to enter dapps";

    try {
      // console.log("signing....");
      const aa = signMessage({
        message: msg,
      });
    } catch (error) {
      console.error("Error signing message:", error);
      //setSigning(false);
      throw error;
    }
  }

  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted && authenticationStatus !== "loading";
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === "authenticated");
        //if (chain.unsupported) setCorrectChain(!chain.unsupported);a
        return (
          <div
            {...(!ready && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <button
                    onClick={openConnectModal}
                    className="w-[300px] text-2xl  lg:flex lg:text-[44px] px-6 py-3 leading-none font-passion text-warm-white rounded-lg bg-[#1E3557] "
                  >
                    Connect Wallet
                  </button>
                );
              }
              if (connected && !signed) {
                return (
                  <button
                    onClick={async () => {
                      var signature = await signTypedData(acc_.address);
                      return;
                    }}
                    className="w-[300px] text-2xl  lg:flex lg:text-[44px] px-6 py-3 leading-none font-passion text-warm-white rounded-lg bg-[#1E3557] "
                  >
                    SIGN TO ENTER
                  </button>
                );
              }
              if (chain.unsupported) {
                return (
                  <button
                    onClick={openConnectModal}
                    className="w-[200px] text-2xl  lg:flex lg:text-[24px] px-6 py-3 leading-none font-passion text-warm-white rounded-lg bg-[#1E3557] "
                  >
                    Wrong network
                  </button>
                );
              }
              return (
                <div
                  style={{ display: "flex", gap: 12 }}
                  className="w-full text-center items-center align-middle justify-center text-dark-blue"
                >
                  {"Loading Data..."}
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};
