import React, { useEffect, useState, useContext } from "react";
import ERC20HX from "../../abi/ERC20HXFeesOracle.json";
import { NumericFormat } from "react-number-format";
import { AppContext } from "../../../../context/AppProvider";
import {
  useReadContracts,
  useAccount,
  useBlockNumber,
  usePublicClient,
  useWaitForTransactionReceipt,
  useWriteContract,
} from "wagmi";
import { formatUnits, parseUnits } from "viem";

const XDRAGON_ERC20_CONTRACT = process.env.REACT_APP_XDRAGON_ERC20_CONTRACT;

// Captures 0x + 4 characters, then the last 4 characters.
const truncateRegex = /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/;

/**
 * Truncates an ethereum address to the format 0x0000…0000
 * @param address Full address to truncate
 * @returns Truncated address
 */
const truncateEthHex = (address) => {
  const match = address.match(truncateRegex);
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

const Bint = () => {
  const {
    setRefreshXData, //xdragon
  } = useContext(AppContext);
  const { address, isConnecting, isDisconnected } = useAccount();
  const [lastTxHash, setLastTxHash] = useState();
  const [amountToBint, setAmountToBint] = useState();
  const [availableToBint, setAvailableToBint] = useState(0n);
  const [bintFees, setBintFees] = useState(0);
  const [isBinting, setIsBinting] = useState(false);
  const [allowBint, setAllowBint] = useState(false);

  const { writeContractAsync } = useWriteContract();

  const waitLastTx = useWaitForTransactionReceipt({
    hash: lastTxHash,
    confirmations: 2,
  });

  const erc20ContractReader = useReadContracts({
    contracts: [
      {
        abi: ERC20HX.abi,
        address: XDRAGON_ERC20_CONTRACT,
        functionName: `balanceOf`,
        args: [address],
      },
      {
        abi: ERC20HX.abi,
        address: XDRAGON_ERC20_CONTRACT,
        functionName: `fee`,
        args: [1],
      },
    ],
    query: {
      enabled: true,
      refetchInterval: 2000,
      refetchIntervalInBackground: true,
    },
  });

  // is eligible to bint if minimum input amount 1 and not more than avaiable to bint
  const isAbleTobint =
    toETH(amountToBint) >= 0 && toETH(amountToBint) < availableToBint;

  // useEffect(() => {
  //   var a = toETH(amountToBint) > availableToBint || toETH(amountToBint) <= 0;
  //   setIsButtonBintDisable(a);
  // }, [amountToBint]);

  function toETH(n) {
    return n * 10 ** 18;
  }

  const handleBint = async () => {
    setIsBinting(true);
    setAllowBint(false);
    try {
      const txHash = await writeContractAsync({
        address: XDRAGON_ERC20_CONTRACT,
        abi: ERC20HX.abi,
        functionName: `mintNFT`,
        args: [BigInt(amountToBint)], // eslint-disable-line
        value: bintFees,
      });

      setLastTxHash(txHash);
      setRefreshXData(true);
      setIsBinting(false);
      setAmountToBint(0);
    } catch (e) {
      console.log(e);
      setIsBinting(false);
      setAmountToBint(0);
      setAllowBint(false);
    }
  };

  useEffect(() => {
    var allow =
      toETH(amountToBint) > 0 && toETH(amountToBint) <= availableToBint;
    setAllowBint(allow);
  }, [amountToBint]);

  const onSuccessReadContracts = useEffect(() => {
    if (
      (erc20ContractReader.data &&
        erc20ContractReader.data[0]?.status === "success") ||
      (!waitLastTx.isLoading && waitLastTx.isSuccess)
    ) {
      setAvailableToBint(erc20ContractReader.data[0].result);
    } else {
      setAvailableToBint(NaN);
    }
    if (
      amountToBint &&
      erc20ContractReader.data &&
      erc20ContractReader.data[1]?.status === "success"
    )
      setBintFees(
        (erc20ContractReader.data[1].result * BigInt(amountToBint) * 125n) / // eslint-disable-line
          100n
      );
  }, [
    !erc20ContractReader.isLoading &&
      erc20ContractReader.isSuccess &&
      erc20ContractReader.data,
    amountToBint,
    !waitLastTx.isLoading && waitLastTx.isSuccess && waitLastTx.data,
  ]);

  return (
    <div className="w-full bg-[#DFDAC9] rounded-md min-h-60">
      {/* available to bint */}
      <div className="available-bint w-full flex justify-between p-5">
        <span className="text-lg font-black text-[#1E3557] ">
          Available to Bint:
        </span>
        <span className="text-lg font-black text-[#1E3557] ">
          {formatUnits(availableToBint, 18)}
        </span>
      </div>

      {/* input amount section */}
      <div className="input-amount-bint w-full flex flex-col text-dark-blue font-passion justify-between pt-2 pr-5 pl-5 pb-5">
        <NumericFormat
          className="w-full p-2 rounded-xl focus:outline-none number-input-container bg-white text-xl lg:text-2xl  text-[#1E3557] text-left "
          value={amountToBint}
          placeholder="Amount to bint"
          isAllowed={(values) => {
            const { value } = values;
            return (
              !value.includes(".") &&
              !value.includes(",") &&
              !waitLastTx.isLoading
            );
          }}
          allowNegative={false}
          thousandSeparator={false}
          onValueChange={({ floatValue }) => {
            setAmountToBint(floatValue);
          }}
        />
        {/* info text */}
        <span className=" pt-2 text-[#475467]">
          {`Total Bint Fees: ${parseFloat(formatUnits(bintFees, 18)).toFixed(
            6
          )}`}
        </span>
      </div>

      {/* bint button */}
      <div className="input-amount-bint w-full flex justify-between pt-2 pr-5 pl-5 pb-5">
        <button
          className={`w-full p-3 rounded-lg text-3xl font-black text-white font-passion ${
            allowBint ? "bg-[#0754C4]" : "bg-[#7E91AE]"
          }`}
          onClick={handleBint}
          disabled={!allowBint}
        >
          {!isBinting ? "BINT" : "BINT in progress.."}
        </button>
      </div>
      <span className="text-black">
        {lastTxHash ? (
          waitLastTx.isLoading ? (
            <div className="animate-pulse">`⌛ Processing, please wait...`</div>
          ) : (
            `✅ Last txHash: ${truncateEthHex(lastTxHash)}`
          )
        ) : (
          ""
        )}
      </span>
    </div>
  );
};

export default Bint;
