import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";

export default function FAQComponent() {
  return (
    <div className="w-full">
      <div className="mx-auto w-full max-w-md px-3 flex text-4xl  text-white font-passion">
        FAQ
      </div>
      <div className="mx-auto w-full max-w-md rounded-2xl p-2">
        {/* FAQ 1  What is XDragon*/}
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`flex w-full justify-between bg-[#1e3557] px-4 py-3 text-left text-xl font-bold text-warm-white ${
                  open ? "rounded-t-lg" : "rounded-lg"
                }`}
              >
                <span>What is XDragon?</span>
                <ChevronUpIcon
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-7 w-7 text-warm-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pb-4 pt-4 text-sm bg-[#1e3557] text-warm-white text-left">
                The first hybrid ERC20/ERC721 token utilizes the
                <a
                  href="https://hybridx.org"
                  className="font-bold text-bright-red"
                  target="blank"
                >
                  {" "}
                  HybridX{" "}
                </a>
                standard with on-chain gamblification in ETH L2 - Arbitrum.
                Supply for XDragon is limited 3838 only. <br />
                XDragon holders are the OGs and early members of House of the
                Dragon, entitled to 5% of the native token allocation for the
                Dragon Eyes ecosystem, which streams the house profit after TGE.
                <br />
                The objective of XDragon is to fundraise and establish a strong
                foundation and community for the House of the Dragon.
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        {/* FAQ 2 How to mint XDragon?*/}
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`flex w-full justify-between bg-[#1e3557] px-4 py-3 mt-3 text-left text-xl font-bold text-warm-white ${
                  open ? "rounded-t-lg" : "rounded-lg"
                }`}
              >
                <span>How to mint XDragon?</span>
                <ChevronUpIcon
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-7 w-7 text-warm-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pb-4 pt-4 text-sm bg-[#1e3557] text-warm-white text-left">
                You need $EYES to mint.
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        {/* FAQ 3 How to get more $EYES? */}
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`flex w-full justify-between bg-[#1e3557] px-4 py-3 mt-3 text-left text-xl font-bold text-warm-white ${
                  open ? "rounded-t-lg" : "rounded-lg"
                }`}
              >
                <span>How to get more $EYES?</span>
                <ChevronUpIcon
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-7 w-7 text-warm-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pb-4 pt-4 text-sm bg-[#1e3557] text-warm-white text-left">
                1. By buying ticket and playing the Dragon Eyes game. For each
                dice roll you will get $EYES depend on the sum of dice number
                you get. Higher number gets more $EYES.
                <br />
                <br />
                2. By sharing your invite code. For each code used by user you
                referred that bought ticket, you will get 1,000 $EYES or the
                equivalent of double eyes (1-1) in Arbitrum version.
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        {/* FAQ 4 How to Migrate */}
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`flex w-full justify-between bg-[#1e3557] px-4 py-3 mt-3 text-left text-xl font-bold text-warm-white ${
                  open ? "rounded-t-lg" : "rounded-lg"
                }`}
              >
                <span>
                  How to migrate $EYES I get from ICP version of Dragon Eyes?
                </span>
                <ChevronUpIcon
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-7 w-7 text-warm-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pb-4 pt-4 text-sm bg-[#1e3557] text-warm-white text-left">
                Use the MIGRATE tab and login with the Google account you use
                for the ICP version. Click MIGRATE and you will get your $EYES
                available to mint XDragon
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        {/* FAQ 5 What is House of the Dragon? */}
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`flex w-full justify-between bg-[#1e3557] px-4 py-3 mt-3 text-left text-xl font-bold text-warm-white ${
                  open ? "rounded-t-lg" : "rounded-lg"
                }`}
              >
                <span>What is House of the Dragon?</span>
                <ChevronUpIcon
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-7 w-7 text-warm-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pb-4 pt-4 text-sm bg-[#1e3557] text-warm-white text-left">
                An organization that builds, owns, and manages a fully
                decentralized casino, which initially started with a live
                "Dragon Eyes" dice rolling game. The casino's profits are
                distributed to members according to their percentage of
                ownership in the native token.
                <br />
                <br />
                The TGE of House of the Dragon native token will be done
                sometime this year, after more games are built.
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        {/* FAQ 6 What’s the different between ERC-404 and HybridX? */}
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`flex w-full justify-between bg-[#1e3557] px-4 py-3 mt-3 text-left text-xl font-bold text-warm-white ${
                  open ? "rounded-t-lg" : "rounded-lg"
                }`}
              >
                <span>What’s the different between ERC-404 and HybridX?</span>
                <ChevronUpIcon
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-7 w-7 text-warm-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pb-4 pt-4 text-sm bg-[#1e3557] text-warm-white text-left">
                In ERC-404, both Non Fungible (ERC-721) and Fungible (ERC-20)
                token exist together at the same time. In HybridX, if NFT
                exists, the FT will be burned, and vice versa.
                <br />
                <br />
                HybridX is like Batman OR Bruce Wayne that cannot exist both at
                the same time, while ERC-404 is Two-Face which has DID
                (Dissasociative Identity Disorder) complex.
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        {/* FAQ 7 What’s the benefit of holding XDragon? */}
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`flex w-full justify-between bg-[#1e3557] px-4 py-3 mt-3 text-left text-xl font-bold text-warm-white ${
                  open ? "rounded-t-lg" : "rounded-lg"
                }`}
              >
                <span>What’s the benefit of holding XDragon?</span>
                <ChevronUpIcon
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-7 w-7 text-warm-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pb-4 pt-4 text-sm bg-[#1e3557] text-warm-white text-left">
                In the NFT form, you will get distribution of shapeshift (BINT /
                MURN) fees. You will need to BINT first after you mint XDragon
                in fungible mode.
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        {/* FAQ 8  What is Shapeshift? */}
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`flex w-full justify-between bg-[#1e3557] px-4 py-3 mt-3 text-left text-xl font-bold text-warm-white ${
                  open ? "rounded-t-lg" : "rounded-lg"
                }`}
              >
                <span>What is Shapeshift?</span>
                <ChevronUpIcon
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-7 w-7 text-warm-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pb-4 pt-4 text-sm bg-[#1e3557] text-warm-white text-left">
                Changing the form of your XDragon from Non Fungible to Fungible
                or vice versa.
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        {/* FAQ 9  What is BINT? */}
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`flex w-full justify-between bg-[#1e3557] px-4 py-3 mt-3 text-left text-xl font-bold text-warm-white ${
                  open ? "rounded-t-lg" : "rounded-lg"
                }`}
              >
                <span>What is BINT?</span>
                <ChevronUpIcon
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-7 w-7 text-warm-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pb-4 pt-4 text-sm bg-[#1e3557] text-warm-white text-left">
                The shapeshift action from FT to NFT by burning the ERC-20 (FT)
                and minting ERC-721 (NFT). You need to pay 1% fee in ETH and you
                will get 1 ticket per 1 NFT BINT to roll the dice.
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        {/* FAQ 10  What is MURN? */}
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`flex w-full justify-between bg-[#1e3557] px-4 py-3 mt-3 text-left text-xl font-bold text-warm-white ${
                  open ? "rounded-t-lg" : "rounded-lg"
                }`}
              >
                <span>What is MURN?</span>
                <ChevronUpIcon
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-7 w-7 text-warm-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pb-4 pt-4 text-sm bg-[#1e3557] text-warm-white text-left">
                The shapeshift action from NFT to FT by burning the ERC-721
                (NFT) and minting ERC-20 (FT). You also need to pay 1% fee in
                ETH.
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        {/* FAQ 11   Where do the BURN and MINT fees go? */}
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`flex w-full justify-between bg-[#1e3557] px-4 py-3 mt-3 text-left text-xl font-bold text-warm-white ${
                  open ? "rounded-t-lg" : "rounded-lg"
                }`}
              >
                <span> Where do the BURN and MINT fees go?</span>
                <ChevronUpIcon
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-7 w-7 text-warm-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pb-4 pt-4 text-sm bg-[#1e3557] text-warm-white text-left">
                20% are distributed to all token holder who hold in NFT form,
                and 80% goes to Dragon pot as a prize for onchain dice rolling
                game.
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        {/* FAQ 12   When and why do I need to BINT? */}
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`flex w-full justify-between bg-[#1e3557] px-4 py-3 mt-3 text-left text-xl font-bold text-warm-white ${
                  open ? "rounded-t-lg" : "rounded-lg"
                }`}
              >
                <span>When and why do I need to BINT?</span>
                <ChevronUpIcon
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-7 w-7 text-warm-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pb-4 pt-4 text-sm bg-[#1e3557] text-warm-white text-left">
                Binting your XDragon to NFT form provides several benefits:
                <ul>
                  <li>🎲 You get your artwork PFP</li>
                  <li>
                    🎲 You get distribution of shapeshift fees you may claim
                    anytime
                  </li>
                  <li>
                    🎲 You can list your NFT in marketplace like OpenSea, Blur,
                    LooksRare etc.
                  </li>
                  <li>
                    🎲 You get 1 ticket per NFT to roll the dice for a chance to
                    win the pot that collects 80% of all shapeshift fees.
                  </li>
                </ul>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        {/* FAQ 13   When and why do I need to MURN? */}
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`flex w-full justify-between bg-[#1e3557] px-4 py-3 mt-3 text-left text-xl font-bold text-warm-white ${
                  open ? "rounded-t-lg" : "rounded-lg"
                }`}
              >
                <span>When and why do I need to MURN?</span>
                <ChevronUpIcon
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-7 w-7 text-warm-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pb-4 pt-4 text-sm bg-[#1e3557] text-warm-white text-left">
                Murning your XDragon to FT is necessary when you want to sell
                your token on Uniswap.
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        {/* FAQ 14    When and why do I need to Re-Roll? */}
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`flex w-full justify-between bg-[#1e3557] px-4 py-3 mt-3 text-left text-xl font-bold text-warm-white ${
                  open ? "rounded-t-lg" : "rounded-lg"
                }`}
              >
                <span> When and why do I need to Re-Roll?</span>
                <ChevronUpIcon
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-7 w-7 text-warm-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pb-4 pt-4 text-sm bg-[#1e3557] text-warm-white text-left">
                Re-Rolling your XDragon will get you different randomized PFP,
                and you will get 1 ticket to roll the dice for a chance to win
                the pot that collects 80% of all shapeshift fees.
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        {/* FAQ 15 How the XDragon onchain gamblification works? */}
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`flex w-full justify-between bg-[#1e3557] px-4 py-3 mt-3 text-left text-xl font-bold text-warm-white ${
                  open ? "rounded-t-lg" : "rounded-lg"
                }`}
              >
                <span>How the XDragon onchain gamblification works?</span>
                <ChevronUpIcon
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-7 w-7 text-warm-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pb-4 pt-4 text-sm bg-[#1e3557] text-warm-white text-left">
                🎲 For each BINT and MURN fees collected, 80% of them goes to
                Dragon prize pot. Holder get a dice roll ticket for every 1 NFT
                BINT.
                <br />
                <br />
                🎲 Holder win and emptied all the money in the Dragon pot when
                they get double six (6-6) and round will be restarted.
                <br />
                <br />
                🎲 The pot will be refilled again automatically from all the
                collective fees from the BINT & MURN activities.
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        {/* FAQ 16 What is the chance of winning the pot? */}
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`flex w-full justify-between bg-[#1e3557] px-4 py-3 mt-3 text-left text-xl font-bold text-warm-white ${
                  open ? "rounded-t-lg" : "rounded-lg"
                }`}
              >
                <span>What is the chance of winning the pot?</span>
                <ChevronUpIcon
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-7 w-7 text-warm-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pb-4 pt-4 text-sm bg-[#1e3557] text-warm-white text-left">
                2 die with each 6 sides means that your chance of winning the
                pot each time rolling the dice statistically is 1/36 or 2.77%.
                The{" "}
                <a
                  href="https://internetcomputer.org/docs/current/motoko/main/base/Random"
                  className="text-bright-red"
                >
                  randomization is done fully onchain using secured VRF
                  (Verifiable Randomized Function)
                </a>{" "}
                on the ICP network.
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        {/* FAQ 17 How the XDragon holders fee distribution works? */}
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`flex w-full justify-between bg-[#1e3557] px-4 py-3 mt-3 text-left text-xl font-bold text-warm-white ${
                  open ? "rounded-t-lg" : "rounded-lg"
                }`}
              >
                <span>How the XDragon holders fee distribution works?</span>
                <ChevronUpIcon
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-7 w-7 text-warm-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pb-4 pt-4 text-sm bg-[#1e3557] text-warm-white text-left">
                🎲 For each BINT and MURN fees collected, 20% distributed to a
                claim pool for XDragon NFT holders.
                <br />
                🎲 Every 24 hours there will be snapshot for all NFT holders to
                determine the allocation of fees for that day. Holders may claim
                their fees from the pool anytime.
                <br />
                🎲 Holders in FT form are not entitled for fee distribution.
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        {/* FAQ 18 How to claim fee distribution? */}
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`flex w-full justify-between bg-[#1e3557] px-4 py-3 mt-3 text-left text-xl font-bold text-warm-white ${
                  open ? "rounded-t-lg" : "rounded-lg"
                }`}
              >
                <span>How to claim fee distribution?</span>
                <ChevronUpIcon
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-7 w-7 text-warm-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pb-4 pt-4 text-sm bg-[#1e3557] text-warm-white text-left">
                Go to CLAIM tab and claim your ETH rewards.
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`flex w-full justify-between bg-[#1e3557] px-4 py-3 mt-3 text-left text-xl font-bold text-warm-white ${
                  open ? "rounded-t-lg" : "rounded-lg"
                }`}
              >
                <span>Contact</span>
                <ChevronUpIcon
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-7 w-7 text-warm-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pb-4 pt-4 text-sm bg-[#1e3557] text-warm-white text-left">
                go to our Discord, X, or email icdragoneyes@gmail.com
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
}
