import AppProvider from "./context/AppProvider";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
//import { QueryClient, QueryClientProvider } from "react-query";

import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

import Layout from "./layout";
import Landing from "./containers/Landing";

import "@rainbow-me/rainbowkit/styles.css";

import { getDefaultConfig, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { WagmiProvider } from "wagmi";
import { arbitrum,goerli } from "wagmi/chains";
const queryClient = new QueryClient();


function getConfig(){
  if(process.env.REACT_APP_CHAIN_CONFIG == "DEV"){
    return getDefaultConfig({
      appName: "Dragon Eyes",
      projectId: "ac82f132063028b81bff73ea105122c7",
      chains: [goerli],
      ssr: true, // If your dApp uses server side rendering (SSR)
    });
  }else {
    return getDefaultConfig({
      appName: "Dragon Eyes",
      projectId: "ac82f132063028b81bff73ea105122c7",
      chains: [arbitrum],
      ssr: true, // If your dApp uses server side rendering (SSR)
    });
  }
}

const config = getConfig();
/*const config = getDefaultConfig({
  appName: "Dragon Eyes",
  projectId: "ac82f132063028b81bff73ea105122c7",
  chains: [arbitrum,goerli],
  ssr: true, // If your dApp uses server side rendering (SSR)
}); */

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Landing />,
      },
      {
        path: "/twitter",
        element: <></>,
      },
    ],
  },
]);

const App = () => {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
          <AppProvider>
            <RouterProvider router={router} />
          </AppProvider>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};

export default App;
