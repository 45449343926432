import { useSendTransaction, usePrepareTransactionRequest } from "wagmi";
import React, { useContext, useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { AppContext } from "../context/AppProvider";
import { useTwitter } from "../hooks/useTwitter";
import RefreshModal from "../components/RefreshModal";
export default function EnterInviteCodeTab() {
  useSendTransaction();
  const { setCode, setIsGenesis, canisterActor } = useContext(AppContext);
  const acc_ = useAccount();
  const [verifying, setVerifiying] = useState(false);
  const [inviteCode, setInviteCode] = useState("");
  const [verifyStep, setVerifyStep] = useState(1);
  const [nocode, setNocode] = useState("");

  const handleInputChange = (event) => {
    const newValue = event.target.value.toString(); // Ensure the value is at least 100
    //dispatch(changeInvestment(newValue));
    setInviteCode(newValue);
  };
  const { data: user } = useTwitter();

  const handleVerify = async () => {
    setNocode("");
    setVerifiying(true);
    var reward_ = await canisterActor.initialMap(acc_.address, inviteCode);

    setVerifiying(false);
    console.log(reward_);
    if (reward_.none) {
      setCode(false);
      setNocode("invitation code invalid");
    } else if (reward_.genesis) {
      setIsGenesis(true);
      setCode(reward_.genesis);
    } else {
      setIsGenesis(false);
      setCode(reward_.invitation);
    }
  };

  //https://twitter.com/i/oauth2/authorize?client_id=bUltUzdwb1RpaF9ELWlEczlNNHI6MTpjaQ&code_challenge=Jup2MEYveMwqRen2&code_challenge_method=plain&redirect_uri=https%3A%2F%2Fbuzz-api.bsquared.network%2Fapi%2Ftwitter%2Fcallback&response_type=code&scope=tweet.read+users.read&state=4G0z4oW6X7ZEPcSS
  const TWITTER_CLIENT_ID = "VlFrcmduaUFZRFRlbFloR3NEODM6MTpjaQ"; // give your twitter client id here

  // buzz bUltUzdwb1RpaF9ELWlEczlNNHI6MTpjaQ
  //kin UGVrbVRzMnQzZVpTaUZzOTIta3U6MTpjaQ
  //n6qePDNkZeuBAWebcX1lpip1m
  // twitter oauth Url constructor

  function getTwitterOauthUrl() {
    var redir = "http://localhost:3001/oauth/twitter";
    if (process.env.REACT_APP_CHAIN_CONFIG == "DEV") {
      redir = "http://localhost:3001/oauth/twitter";
    } else {
      redir = "https://api.dragoneyes.xyz/oauth/twitter";
    }
    redir = "https://api.dragoneyes.xyz/oauth/twitter";
    const rootUrl = "https://twitter.com/i/oauth2/authorize";
    const options = {
      client_id: TWITTER_CLIENT_ID,
      code_challenge: "challenge",

      code_challenge_method: "plain",
      redirect_uri: redir, // client url cannot be http://localhost:3000/ or http://127.0.0.1:3000/
      response_type: "code",
      state: "state",

      scope: ["users.read", "tweet.read", "follows.read", "follows.write"].join(
        " "
      ), // add/remove scopes as needed
    };
    const qs = new URLSearchParams(options).toString();
    return `${rootUrl}?${qs}`;
  }

  return (
    <div className="relative z-50 w-full">
      {/* Overlay */}
      <RefreshModal />
      <div className="flex items-center justify-center w-full  inset-0 overflow-y-auto ">
        <div className="  mt-5 lg:w-[500px] w-full items-center justify-center p-2 text-center">
          {/* Main modal */}

          <div className="w-full max-w-md transform overflow-hidden rounded-2xl bg-warm-white p-0 mt-0 text-left align-middle shadow-xl transition-all relative">
            <div className="text-center grid w-full lg:text-xl text-sm">
              <div className="text-sm px-10 text-dark-blue">
                <div className="pt-5 font-passion text-3xl">
                  Enter invite code to access XDragon page
                </div>
                <div className="w-full grid  bg-dashboard-blue p-2 border bg-dark-blue border-[#1E3557] bg-opacity-90 rounded-2xl my-5 ">
                  <div className="text-center grid w-full lg:text-lg font-passion text-sm">
                    <input
                      className="w-full p-2  rounded-xl  number-input-container bg-white text-2xl lg:text-3xl  text-[#1E3557] text-left "
                      type="text"
                      value={inviteCode}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="text-sm">
                  don’t have code? Join{" "}
                  <a
                    href="https://discord.gg/2G2XfVgJ"
                    className=" font-bold text-bright-red"
                    target="_blank"
                  >
                    our discord
                  </a>{" "}
                  and ask someone
                </div>
              </div>
            </div>

            <div className="text-center grid w-full lg:text-xl text-sm">
              <div className="text-sm px-10 text-dark-blue">
                <div className="pt-5 font-passion text-3xl">
                  And follow us on X
                </div>
              </div>
            </div>

            {verifyStep != 3 ? (
              <div className="">
                <div className="pt-2 w-full flex text-xl lg:text-2xl py-8 px-10 font-passion leading-6 text-gray-900 items-center justify-center text-center">
                  <div className="py-0 px-2 rounded-2xl w-full flex text-xl lg:text-2xl font-passion  text-gray-900 items-center justify-center text-center">
                    <div className="w-[200px] grid mx-6  bg-dashboard-blue p-2 border bg-dark-blue text-white  bg-opacity-90 rounded-2xl my-5 ">
                      {verifyStep == 1 ? (
                        <div className="text-center grid w-full lg:text-lg font-passion text-sm">
                          <a
                            target="blank"
                            onClick={() => {
                              setVerifyStep(2);
                              window.open(
                                "https://twitter.com/intent/follow?screen_name=icdragoneyes",
                                "_blank",
                                "width=400, height=400"
                              );
                            }}
                          >
                            Follow @ICDragonEyes
                          </a>
                        </div>
                      ) : verifyStep == 2 ? (
                        <div className="text-center grid w-full lg:text-lg font-passion text-sm">
                          <a
                            onClick={() => {
                              setVerifyStep(3);
                            }}
                          >
                            Verify X Following
                          </a>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="column-container"></div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}

            {verifyStep == 3 ? (
              <div className="">
                <div className="pt-2 w-full flex text-xl lg:text-2xl px-10 font-passion leading-6 text-gray-900 items-center justify-center text-center">
                  <div className="py-0 px-2 rounded-2xl w-full flex text-xl lg:text-2xl font-passion  text-[#0753c4] items-center justify-center text-center">
                    X Verified ✅<div className="column-container"></div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}

            {verifyStep == 3 ? (
              <div className="">
                <div className="pt-2 w-full flex text-xl lg:text-2xl px-10 font-passion leading-6 text-gray-900 items-center justify-between text-left">
                  <div className="py-0 px-2 rounded-2xl w-full flex text-xl lg:text-2xl font-passion  text-gray-900 items-center justify-between text-left">
                    <div className="w-full grid mx-6  bg-dashboard-blue p-2 border bg-bright-red  bg-opacity-90 rounded-2xl my-5 ">
                      <div className="text-center grid w-full lg:text-lg font-passion text-sm">
                        <div
                          className="flex w-full text-center justify-center items-center cursor-pointer text-warm-white text-3xl"
                          onClick={() => {
                            handleVerify();
                          }}
                        >
                          {verifying ? "VERIFYING....." : "VERIFY INVITE CODE"}
                        </div>
                      </div>
                    </div>
                    <div className="column-container"></div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}

            <div className="">
              <div className="pt-2 w-full flex text-xl lg:text-2xl px-10 font-passion leading-6 text-gray-900 items-center justify-center text-center">
                <div className="py-0 px-2 pb-8 rounded-2xl w-full flex text-base lg:text-lg font-passion  text-bright-red items-center justify-center text-center">
                  {nocode}
                </div>
              </div>
            </div>

            {/* Close button */}
          </div>
        </div>
      </div>
    </div>
  );
}
