import { useAtom } from "jotai";
import { isTransferModalOpenAtom } from "../store/modal";
import React, { useContext, useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { useAccount } from "wagmi";
import { AppContext } from "../context/AppProvider";
import { Principal } from "@dfinity/principal";

export default function APR() {
  const [isTransferModalOpen, setModalOpen] = useAtom(isTransferModalOpenAtom);
  const [ethBalance, setEthbalance] = useState(0);
  const [eyesAmount, setEyesAmount] = useState();
  const [xPerEyes, setXPerEyes] = useState(1);
  const [xdragonAmount, setXdragonAmount] = useState(0);
  const [isMinting, setIsMinting] = useState(false);
  const [mintResult, setMintResult] = useState(0);
  const [hash, setHash] = useState("");
  const [arbiscan, setArbiscan] = useState("");

  const {
    eyesBalance,
    isGenesis,
    code,
    canisterActor, //xdragon
    walletAddress,
    eyesLedger,
    changeAddress,
    setRequestUpdateEyes,
    pxb,
    lastPotWinner,
    totalFees,
    apr,
    xtoEth,
    yesterdayFee,
    xPot,
    gasFee,
  } = useContext(AppContext);

  function truncateString(str, num) {
    if (!str) return "";
    if (str.length <= num) {
      return str;
    }
    const frontChars = Math.ceil(num / 2);
    const backChars = Math.floor(num / 2);
    return (
      str.substr(0, frontChars) + "..." + str.substr(str.length - backChars)
    );
  }

  function copyHashToClipboard() {
    //const textToCopy = walletAddress; // Replace with your actual string variable
    const textToCopy = hash;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        alert("tx hash copied to clipboard");
      })
      .catch((err) => {
        console.error("Error in copying text: ", err);
      });
  }

  const acc_ = useAccount();

  const [ethformatted, setEthformatted] = useState(0);

  const convertedEyesBalance = Number(eyesBalance) / 100000000;

  // minimum 100
  // maximum same as eyesBalance
  const isValidAmountToMint =
    eyesAmount >= 100 && eyesAmount <= convertedEyesBalance;

  // console.log(isValidAmountToMint, "<<<<<< wadiaw");

  async function handleMint() {
    setMintResult(0);
    setHash("");
    setArbiscan("");
    try {
      setIsMinting(true);

      //console.log(walletAddress, "<<<<<<<waellet");
      var acc = {
        owner: Principal.fromText(walletAddress),
        subaccount: [],
      };

      var XDragonSmartContract = {
        owner: Principal.fromText(process.env.REACT_APP_CANISTER_ID),
        subaccount: [],
      };

      var approve_ = {
        fee: [],
        memo: [],
        from_subaccount: [],
        created_at_time: [],
        amount: eyesAmount * 100000000,
        expected_allowance: [],
        expires_at: [],
        spender: XDragonSmartContract,
      };

      var approval = await eyesLedger.icrc2_approve(approve_);

      console.log("minting..");
      var a = await canisterActor.mintXDRAGON(eyesAmount * 100000000); //times 10**8 decimals for EYES
      console.log(a, "<<<<<< mint result");

      if (a.success) {
        setMintResult(1);
        setArbiscan("https://arbiscan.io/tx/" + a.success);
        setHash(a.success.toString());
      } else if (a.error) {
        setMintResult(2);
        setHash(a.errror);
      } else if (a.timeout) {
        setMintResult(1);
        setArbiscan("https://arbiscan.io/tx/" + a.timeout);
        setHash(a.timeout);
      }
      setIsMinting(false);
      setRequestUpdateEyes(true);
      setEyesAmount(0);
      setXdragonAmount(0);
    } catch (e) {
      setIsMinting(false);
      console.log(e, "<<<<<err minting");
    }
  }

  function copyToClipboard() {
    const textToCopy = code;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        alert("Code copied to clipboard");
      })
      .catch((err) => {
        console.error("Error in copying text: ", err);
      });
  }

  const shareText = `Mint XDragon — The 1st Hybrid ERC20/721 using HybridX standard with onchain gamblification

use my invite code: ${code}
  
Mint page: https://x.dragoneyes.xyz
Follow @ICDragonEyes `;

  const handleShare = () => {
    const tweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      shareText
    )}`;
    window.open(tweetUrl, "_blank");
  };

  return (
    <div className="relative z-10 w-full">
      {/* Overlay */}

      <div className="flex items-center justify-center w-full  inset-0 overscroll-contain mb-5  ">
        <div className=" w-full items-center justify-center text-center ">
          <div className="w-full  transform overflow-hidden rounded-2xl bg-warm-white p-5  mt-0 text-left align-middle">
            <div className="flex w-[100%] bg-[#dfdac9] py-2 rounded-2xl ">
              <div className="pt-1 w-[65%] grid text-base lg:text-lg px-2 font-passion leading-6 text-gray-900 pr-4  text-left">
                <div className="pt-1 w-full flex text-sm lg:text-base items-center justify-between  text-left">
                  <div className="px-2  text-xs lg:text-sm text-left w-[65%] ">
                    Total Fees Distributed
                  </div>{" "}
                  <div className="text-green-600 w-[35%] ">
                    {" "}
                    {parseFloat(totalFees / 10 ** 18)
                      .toFixed(2)
                      .toLocaleString()}{" "}
                    ETH
                  </div>
                </div>
                <div className="pt-1 w-full flex text-sm lg:text-base items-center justify-between  text-left">
                  <div className="px-2 text-xs lg:text-sm text-left w-[65%]">
                    Current Dragon pot
                  </div>{" "}
                  <div className="text-green-600 w-[35%] ">
                    {parseFloat(xPot / 10 ** 18)
                      .toFixed(2)
                      .toLocaleString()}{" "}
                    ETH
                  </div>
                </div>
                <div className="pt-1 w-full flex text-sm lg:text-base items-center  justify-between text-left">
                  <div className="px-2 text-xs lg:text-sm text-left w-[65%]">
                    Yesterday's Fee Distribution
                  </div>{" "}
                  <div className="text-green-600 w-[35%] ">
                    {parseFloat(yesterdayFee / 10 ** 18)
                      .toFixed(2)
                      .toLocaleString()}{" "}
                    ETH
                  </div>
                </div>
              </div>
              <div className="w-[35%] h-[100px] m-2 rounded-2xl bg-dark-blue flex justify-center align-middle items-center font-passero  text-3xl text-green-200">
                <div className="text-center grid m-2">
                  <div className="text-lg">APR</div>
                  <div className="text-3xl">
                    {parseFloat(parseFloat((apr / xtoEth) * 365 * 100))
                      .toFixed(2)
                      .toLocaleString()}
                    %
                  </div>
                </div>
              </div>
            </div>
            <div className="text-left grid w-full lg:text-xl text-sm  ">
              <div className="flex text-sm pl-2 text-dark-blue">
                <div className="pt-2 w-[60%]  pr-2 font-passion text-base text-[#1E3557] justify-center items-center  text-left">
                  Get more $EYES by sharing <br />
                  your
                  {isGenesis ? " genesis code " : " invite code "}
                  <span
                    onClick={() => {
                      copyToClipboard();
                    }}
                    className="cursor-pointer px-0 text-[#0753c4]"
                  >
                    {code.toString()}📋
                  </span>
                  <br />
                </div>
                <div className="w-[40%] grid  bg-dashboard-blue p-1 border bg-[#EE5151] border-[#EE5151] rounded-2xl my-5 ">
                  <div className="text-center grid w-full lg:text-lg font-passion text-sm">
                    <div
                      className="flex w-full text-center justify-center items-center cursor-pointer text-warm-white text-xl"
                      onClick={() => handleShare()}
                    >
                      Share
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full bg-dark-blue rounded-md">
                <div className="text-sm w-full px-2 text-[bright-red] font-passion text-center justify-center items-center ">
                  +1000 $EYES each user using your code to buy ticket or mint
                  XDragon
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
