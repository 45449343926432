import { useDrag } from "react-dnd";

const DragAbleItem = ({ nft, onSelectNft }) => {
  const { alt, id, src, isSelected } = nft || {};
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "box",
    item: { alt },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        onSelectNft(id, !isSelected);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));
  const opacity = isDragging ? 0 : 1;
  return (
    <div
      ref={drag}
      style={{ opacity }}
      className="w-24 h-24 cursor-grab "
      data-testid={`box`}
    >
      <img
        src={src}
        alt={alt}
        className="w-full h-full object-cover rounded-lg"
      />
    </div>
  );
};

export default DragAbleItem;
