import React from "react";

const CenteredLayout = ({ children }) => {
  return (
    <div className="flex justify-center max-h-screen pt-4 pb-4 z-10">
      {/*<div className="w-full lg:w-[500px]  overflow-auto thin-scrollbar">*/}
      <div className="w-full lg:w-[500px] z-10 ">{children}</div>
    </div>
  );
};

export default CenteredLayout;
