import { ConnectButton } from "@rainbow-me/rainbowkit";

import { CustomConnect } from "./CustomConnect";
import { useContext } from "react";
import { AppContext } from "../context/AppProvider";

export default function ConnectWalletTab() {
  const {
    loginInstance,
    userData,
    ethconnected,
    signature,
    correctChain,
    code,
  } = useContext(AppContext);

  return (
    <div className="relative z-50 w-full">
      {/* Overlay */}

      <div className="flex items-center justify-center w-full  inset-0 overflow-y-auto ">
        <div className="   lg:w-[500px] w-full items-center justify-center p-2 text-center">
          {/* Main modal */}

          <div className="z-40 relative bg-dark-cream bg-opacity-65 py-2 lg:ml-auto top-5 lg:top-0 mt-32 rounded-lg w-full lg:w-[350px]  px-8 flex flex-col gap-3">
            <div className="flex pt-5 w-full text-center justify-center h-full items-center align-middle cursor-pointer text-warm-white text-3xl">
              {!ethconnected || !signature ? <CustomConnect /> : <></>}
            </div>
            <div className="mt-4"></div>

            {/* Close button */}
          </div>
        </div>
      </div>
    </div>
  );
}
