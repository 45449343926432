import React from "react";
import { Tab } from "@headlessui/react";
import { useAtom } from "jotai";
import { useMediaQuery } from "react-responsive";
import Header from "../../components/Header";
import moneyBag from "../../assets/drgn_base.jpg";
import dice from "../../assets/dice.png";

import { AppContext } from "../../context/AppProvider";
import { useEffect, useState, useContext } from "react";
import { isTransferModalOpenAtom } from "../../store/modal";
import { isRefreshModalOpenAtom } from "../../store/modal";
import { isGameMessageModalOpenAtom } from "../../store/modal";
import WalletModal from "../../components/WalletModal";
import GameMessageModal from "../../components/GameMessageModal";
import RefreshModal from "../../components/RefreshModal";
import Mint from "../../components/Mint";
import XDragon from "../../components/XDragon/index";
import Claim from "../../components/Claim";
import Migration from "../../components/Migration";
import FAQComponent from "../../components/FAQComponent";
import ConnectWalletTab from "../../components/ConnectWalletTab";

import CenteredLayout from "../../components/CenterLayout";

import EnterInviteCodeTab from "../../components/EnterInviteCodeTab";

import "./style.css";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { CustomConnectSmall } from "../../components/CustomConnectSmall";
import { CustomConnect } from "../../components/CustomConnect";
import { ChainGuard } from "../../components/ChainGuard";
import APR from "../../components/APR";
import Share from "../../components/Share";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Landing = () => {
  const [isTransferModalOpen, setTransferModalOpen] = useAtom(
    isTransferModalOpenAtom
  );
  const [isRefreshModalOpen, setRefreshModalOpen] = useAtom(
    isRefreshModalOpenAtom
  );

  const secret = "e10ed9dc1d40db7943f643";
  //const secret = process.env.REACT_DRAGON_K;
  const generalPrivKey =
    "0bc9866cbc181a4f5291476f7be00ca4f11cae6787e10ed9dc1d40db7943f643";

  const { loginInstance, userData, ethconnected, signed, correctChain, code } =
    useContext(AppContext);
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    loginInstance && loginInstance.privKey && userData
      ? setConnected(true)
      : setConnected(false);
  }, [loginInstance, userData, generalPrivKey, code]);

  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const tabsList = [
    {
      id: "mint",
      title: "MINT",
      component: <Mint />,
    },
    {
      id: "xdragon",
      title: "XDRAGON",
      component: <XDragon />,
    },
    {
      id: "claim",
      title: "CLAIM",
      component: <Claim />,
    },
    {
      id: "migration",
      title: "MIGRATION",
      component: <Migration />,
    },
  ];

  const renderTabs = () => {
    return (
      <div className="w-full z-10 max-w-md px-2 py-0 sm:px-0">
        <ChainGuard />
        <WalletModal />
        <RefreshModal />
        <GameMessageModal />
        <APR />

        <Tab.Group>
          <Tab.List className="w-full flex space-x-1 rounded-t-lg bg-zinc-500 p-1">
            {tabsList.map((tab) => {
              return (
                <Tab
                  key={tab.id}
                  className={({ selected }) =>
                    classNames(
                      "w-full rounded-t-lg py-2.5 text-lg font-bold leading-4",
                      selected ? "bg-zinc-700 text-white" : "text-stone-300"
                    )
                  }
                >
                  {tab.title}
                </Tab>
              );
            })}
          </Tab.List>
          <Tab.Panels>
            {tabsList.map((tab) => {
              return <Tab.Panel key={tab.key}>{tab.component}</Tab.Panel>;
            })}
          </Tab.Panels>
        </Tab.Group>
      </div>
    );
  };

  // connected to wrong chain
  if (!correctChain) {
    return (
      <div className="min-h-lvh bg-cover bg-center z-50">
        <ChainGuard />
        <Header className="z-50" /> <WalletModal />
        <CenteredLayout>
          <div className="z-10 relative bg-dark-cream bg-opacity-65 py-2 lg:ml-auto top-5 lg:top-0 mt-32 rounded-lg w-full lg:w-[350px]  px-8 flex flex-col gap-3">
            <p className="text-[28px] text-dark-blue text-center font-passero lg:text-right leading-tight"></p>
            <div className="hidden md:block mx-auto pb-10">
              <CustomConnect />
            </div>
            <div className="block md:hidden mx-auto pb-10">
              <CustomConnect />
            </div>
          </div>
        </CenteredLayout>
      </div>
    );
  }

  return (
    <div className="min-h-lvh bg-cover bg-center z-50">
      <WalletModal />
      <ChainGuard />
      <Header className="z-50" />
      <CenteredLayout>
        <div className="z-10 flex flex-col w-full relative items-center px-1 lg:px-5 gap-10 lg:gap-0">
          {ethconnected && signed ? (
            code ? (
              <>
                {renderTabs()}
                <FAQComponent />
              </>
            ) : (
              <EnterInviteCodeTab />
            )
          ) : (
            <ConnectWalletTab />
          )}
        </div>
      </CenteredLayout>
    </div>
  );
};

export default Landing;
