import axios from "axios";

const chainIdMap = {
  1: "eth",
  5: "eth_goerli",
  42161: "arbitrum"
};

export async function listTokensOfOwner(
  account,
  contractAddress,
  chainId = 1
) {
  const x = await axios.get(
    `https://api.chainbase.online/v1/account/nfts?chain_id=${chainId}&address=${account}&contract_address=${contractAddress}&page=1&limit=100`,
    {
      headers: {
        'x-api-key': `${process.env.REACT_APP_CHAINBASE_API_KEY}`
      }
    }
  );

  console.log(x.data.data)

  if (x.data && x.data.data.length != 0) {
    return new Set(x.data.data.map((val) => val.token_id));
  }

  return [];
}
