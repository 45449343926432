import axios from "axios";

const chainIdMap = {
  1: "eth",
  5: "eth_goerli",
  42161: "arbitrum"
};

export async function listTokensOfOwner(
  account,
  contractAddress,
  chainId = 1
) {
  let contractFilter = {};
  contractFilter[contractAddress] = [];

  const x = await axios.post(
    `https://rpc.ankr.com/multichain/${process.env.REACT_APP_ANKR_ADVANCED_API_KEY}`,
    {

        jsonrpc: "2.0",
        method: "ankr_getNFTsByOwner",
        params: {
          blockchain: [chainIdMap[chainId]],
          walletAddress: account,
          pageSize: 50,
          filter: [contractFilter],
        },
        id: Date.now(),

    }
  );

  if (x.data.result && x.data.result.assets.length != 0) {
    return new Set(Array.from(x.data.result.assets.map((val) => val.tokenId)));
  }

  return [];
}
