import { useSetAtom } from "jotai";
import { useAtom } from "jotai";
import Moralis from "moralis";
import axios from "axios";
import { ethers } from "ethers";
import { http, createConfig } from "@wagmi/core";
import CryptoJS from "crypto-js";
import { arbitrum } from "@wagmi/core/chains";
import { actorCreation, getUserPrincipal } from "../service/icdragoncanister";
import { eyesCreation } from "../service/eyesledgercanister";
import { ChainGuard } from "./ChainGuard";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAccount } from "wagmi";
import snakeLogo from "../assets/dragon_icon_small_.png";
import arbiLogo from "../assets/image/arbilogo.png";
import uniswapLogo from "../assets/image/uniswaplogo.png";
import openseaLogo from "../assets/image/opensealogo.png";
import {
  isModalOpenAtom,
  isBuyModalOpenAtom,
  isClaimModalOpenAtom,
  isTransferModalOpenAtom,
  isRefreshModalOpenAtom,
  isHistoryModalOpenAtom,
  isGuideModalOpenAtom,
} from "../store/modal";
import loka_icon from "../assets/dragon_icon_.png";
import wallet_icon from "../assets/wallet.svg";
import { FaXTwitter } from "react-icons/fa6";
import { FaDiscord } from "react-icons/fa";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppProvider";
import { useMediaQuery } from "react-responsive";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { Principal } from "@dfinity/principal";
import { AccountIdentifier } from "@dfinity/ledger-icp";
import { Layout, Row, Col, Button, theme } from "antd";
import MenuDropdown from "./MenuDropdown";
import Icon, {
  PoweroffOutlined,
  CloseOutlined,
  GoogleOutlined,
} from "@ant-design/icons";
import "./style.css";
import { CustomConnectSmall } from "./CustomConnectSmall";
import { readContract } from "@wagmi/core";

const IconConnect = () => (
  <svg
    width="20"
    height="10"
    viewBox="0 0 20 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.25 0.833375H5.83333C3.53214 0.833375 1.66666 2.69886 1.66666 5.00004C1.66666 7.30123 3.53214 9.16671 5.83333 9.16671H7.5C9.80118 9.16671 11.6667 7.30123 11.6667 5.00004M13.75 9.16671H14.1667C16.4679 9.16671 18.3333 7.30123 18.3333 5.00004C18.3333 2.69886 16.4679 0.833374 14.1667 0.833374H12.5C10.1988 0.833374 8.33333 2.69886 8.33333 5.00004"
      stroke="white"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default function Header() {
  //const ethers = require("ethers");
  const [isButtonOpen, setIsButtonOpen] = useState(false);
  const [accountId, setAccountid] = useState("");
  const [currentETHwallet, setCurrentETHwallet] = useState(false);
  const toggleAccordion = () => {
    setIsButtonOpen(!isButtonOpen);
  };
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const [collapsed, setCollapsed] = useState(false);
  const acc_ = useAccount();
  const [sgn, setSgn] = useState(false);
  const [signing, setSigning] = useState(false);

  const {
    setCanisterActor,
    setSignature,
    walletAddress,
    setWalletAddress,
    userData,
    eyesLedger,
    eyesBalance,
    setEyesBalance,
    toggleMobileMenu,
    setToggleMobileMenu,
    setWalletAlias,
    setEthconnected,
    ethconnected,
    setCorrectChain,
    setEyesLedger,
    setSigned,
    correctChain,
    setCode,
    setIsGenesis,
    setChangeAddress,
    requestUpdateEyes,
    setRequestUpdateEyes,
    setPXB,
    signed,
    setXtoEth,
    setApr,
    setYesterdayFee,
    setTotalFees,
    setLastPotWinner,
    setXPot,
    setGasFee,
    signature,
    setMintingData,
  } = useContext(AppContext);

  function generatePrivateKey(sourceString) {
    // Compute the SHA-256 hash
    const hashed = CryptoJS.SHA256(sourceString).toString(CryptoJS.enc.Hex);

    // Truncate the hash to 64 characters
    const privateKey = hashed.slice(0, 64);

    return privateKey;
  }

  const [isTransferModalOpen, setTransferModalOpen] = useAtom(
    isTransferModalOpenAtom
  );
  const [isRefreshModalOpen, setRefreshModalOpen] = useAtom(
    isRefreshModalOpenAtom
  );

  const [userEyes, setUserEyes] = useState(false);
  const setModalOpen = useSetAtom(isModalOpenAtom);

  const secret = "e10ed9dc1d40db7943f643";
  useEffect(() => {
    async function sm() {
      try {
        await Moralis.start({
          apiKey:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6ImQwM2I5MGNhLWYyOTQtNDIyZi1hZjliLTdkZWY0ODkxZjc1ZiIsIm9yZ0lkIjoiMzgzMDc5IiwidXNlcklkIjoiMzkzNjE2IiwidHlwZUlkIjoiNmI2YzllMDYtMzY1YS00YjczLTgwOTctNjhhMzYyMjUzYWMwIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MTA1Nzg5NjIsImV4cCI6NDg2NjMzODk2Mn0.3CZeHLt8KeOlcfT5pdLhzUExnVCBzS01Uos34tyszGs",
        });
      } catch (e) {}
    }
    sm();
  }, [Moralis]);

  useEffect(() => {
    async function handleLogin() {
      setChangeAddress(true);
      setRefreshModalOpen(true);
      //var signature = false;
      console.log("accessing ICP");
      try {
        try {
          // signature = await signTypedData(acc_.address);
          //setSgn(signature);
          //setSigned(true);
        } catch (e) {
          console.log(e, "sign err for " + acc_.address);
          console.log();
          setRefreshModalOpen(false);
          return;
        }
        setCurrentETHwallet(acc_.address);
        try {
          const response = await Moralis.EvmApi.token.getTokenPrice({
            chain: "0xa4b1",
            include: "percent_change",
            exchange: "uniswapv3",
            address: "0x27800A3453e2583953b50Fbd7F9Dd4d220f7e594",
          });

          setXtoEth(Number(response.raw.nativePrice.value));
        } catch (e) {
          console.error(e, "<<<<<<<<<<< moralis");
        }

        const abi = [
          {
            type: "function",
            name: "balanceOf",
            stateMutability: "view",
            inputs: [{ name: "account", type: "address" }],
            outputs: [{ type: "uint256" }],
          },
          {
            type: "function",
            name: "totalSupply",
            stateMutability: "view",
            inputs: [],
            outputs: [{ name: "supply", type: "uint256" }],
          },
        ];

        const config = createConfig({
          chains: [arbitrum],
          transports: {
            [arbitrum.id]: http(),
          },
        });

        const result = await readContract(config, {
          abi,
          address: process.env.REACT_APP_XDRAGON_ERC20_CONTRACT,
          functionName: "balanceOf",
          args: ["0xF4951699b480893f65bFeAA986321B0e44165602"],
          account: "0xF4951699b480893f65bFeAA986321B0e44165602",
        });
        setPXB(Number(result));

        var privKey = acc_.address + secret;
        //console.log(privKey.length, "priv length");
        var icpKey = generatePrivateKey(signature);
        var principalString_ = getUserPrincipal(privKey).toString();
        var icpPrincipalString_ = getUserPrincipal(icpKey).toString();
        var actor_old = actorCreation(privKey);
        var actor = actorCreation(icpKey);
        setWalletAddress(icpPrincipalString_);
        //RUN DATA MIGRATION
        try {
          var mgrRes = await actor_old.migrateAddr(icpPrincipalString_);
          //console.log(mgrRes, "<<<<<<<<<< mgr res");
        } catch (e) {
          console.log(e, "migration res");
        }
        //END MIGRATION

        setCanisterActor(actor);
        const eyes_ = eyesCreation(icpKey);
        const eyesOld_ = eyesCreation(privKey);

        setEyesLedger(eyes_);

        //RUN EYES MIGRATION
        //console.log("migratin eyes");
        try {
          var accNow = {
            owner: Principal.fromText(icpPrincipalString_),
            subaccount: [],
          };

          var eyesBalance_ = await eyesOld_.icrc1_balance_of(accNow);
          var accOld = {
            owner: Principal.fromText(principalString_),
            subaccount: [],
          };

          var eyesAmt_ = await eyesOld_.icrc1_balance_of(accOld);
          let transferEyesFromOldWallet = {
            to: accNow,
            fee: [],
            memo: [],
            from_subaccount: [],
            created_at_time: [],
            amount: Number(eyesAmt_),
          };
          if (Number(eyesAmt_) > 0)
            var transferResult_ = await eyesOld_.icrc1_transfer(
              transferEyesFromOldWallet
            );

          var eyesBalance_ = await eyesOld_.icrc1_balance_of(accNow);
          //console.log(Number(eyesBalance_), " <<<<<<<<<<<<<<<<<< EBB ");
          //eyesAmt_ = await eyesOld_.icrc1_balance_of(accOld);

          setEyesBalance(eyesBalance_);
        } catch (error) {
          console.log(error, "<<<<<<<< err migrate");
        }
        //END

        var reward_ = false;
        try {
          // console.log("starting user initial map");
          var initialData = await actor.getInitialData(
            acc_.address.toString(),
            "as"
          );
          console.log(initialData, "<<<< get initial data ok");
          //reward_ = await actor.initialMap(acc_.address.toString(), "as");
          reward_ = initialData.initialMap;
          setMintingData(initialData.mintingData);
          var res = await actor.getRemainingRollTicket();
          //var apr = await actor.getAprBase();
          var apr = initialData.stats;

          setApr(Number(apr.apr));
          setYesterdayFee(Number(apr.yesterday));
          setLastPotWinner(apr.last);
          setXPot(Number(apr.pot));
          setTotalFees(Number(apr.total));
          setGasFee(Number(apr.gasFee));
          //console.log(apr, "<<<<<<<<< base data");

          //reward_ = await actor.checkGenesis(acc_.address);
        } catch (err) {
          console.log(err, "initial check error");
        }

        if (reward_.none) {
          setCode(false);
        } else if (reward_.genesis) {
          setIsGenesis(true);
          setCode(reward_.genesis);
        } else {
          setIsGenesis(false);
          setCode(reward_.invitation);
        }
        setChangeAddress(false);

        if (signature) setEthconnected(true);
        else setEthconnected(false);
        setToggleMobileMenu(false);
        setRefreshModalOpen(false);
      } catch (error) {
        setChangeAddress(false);
      }
    }
    var clearSignature = false;

    if (currentETHwallet && currentETHwallet != acc_.address) {
      //isAddrChanged = true;
      console.log("notChecking");
      setCurrentETHwallet(acc_.address);
      setSignature(false);
      setSigned(false);
      clearSignature = true;
    }

    if (acc_.chain == undefined) {
      setCorrectChain(false);
    }
    if (
      acc_ !== undefined &&
      acc_.address !== undefined &&
      acc_.chain !== undefined &&
      signature &&
      !clearSignature
    ) {
      handleLogin();
    }
  }, [acc_.address, acc_.chain, signature]);
  const collapse = () => {
    setToggleMobileMenu(!toggleMobileMenu);
  };

  const getEyesBalance = async () => {
    var acc = {
      owner: Principal.fromText(walletAddress),
      subaccount: [],
    };
    var balance_ = await eyesLedger.icrc1_balance_of(acc);

    var n_ = Number(balance_) / 100000000;
    n_ = parseFloat(n_).toLocaleString();
    setEyesBalance(Number(balance_));
  };

  useEffect(() => {
    if (walletAddress && eyesLedger) {
      var acc = {
        principal: Principal.fromText(walletAddress),
        subaccount: [],
      };
      var accid = AccountIdentifier.fromPrincipal(acc);

      setAccountid(accid.toHex());
      getEyesBalance();
    }
  }, [eyesLedger, walletAddress]);
  useEffect(() => {
    if (requestUpdateEyes) {
      getEyesBalance();
      setRequestUpdateEyes(false);
    }
  }, [requestUpdateEyes]);
  useEffect(() => {}, [toggleMobileMenu]);
  function truncateString(str, num) {
    if (!str) return "";
    if (str.length <= num) {
      return str;
    }
    const frontChars = Math.ceil(num / 2);
    const backChars = Math.floor(num / 2);
    return (
      str.substr(0, frontChars) + "..." + str.substr(str.length - backChars)
    );
  }

  useEffect(() => {
    let timer;

    if (isButtonOpen) {
      // If the accordion is open, set a timer to close it after 3 seconds
      timer = setTimeout(() => {
        setIsButtonOpen(false);
      }, 3000);
    }

    return () => {
      // Clear the timer when the component unmounts or the state changes
      clearTimeout(timer);
    };
  }, [isButtonOpen]);
  return (
    <nav className="grid w-full z-50 ">
      <div className=" w-full h-14 lg:h-20 px-5 lg:px-20 flex justify-center items-center text-sm lg:text-base">
        <div className="w-full max-w-7xl flex items-center justify-between">
          <div className="w-8 lg:w-12">
            <a href="https://dragoneyes.xyz" target="blank">
              <img src={snakeLogo} className="w-full h-full object-cover" />
            </a>
          </div>

          {!isMobile ? (
            <div className="flex items-center gap-3 font-semibold text-[#1E3557]">
              {walletAddress && correctChain ? (
                <>
                  {/*<a className="py-1 px-2 lg:py-2.5 lg:px-4" href="/migrate">
                    Migration
                  </a>*/}
                </>
              ) : (
                <></>
              )}{" "}
              {walletAddress && correctChain ? <> </> : <></>}
              <div className="px-2 flex">
                <a href="https://x.com/icdragoneyes" target="_blank">
                  <FaXTwitter className="h-6 w-6" />
                </a>
              </div>
              <div className="px-2 flex">
                <a href="https://discord.gg/2G2XfVgJ" target="_blank">
                  <FaDiscord className="h-6 w-6" />
                </a>
              </div>
              <div className="px-2 flex">
                <a
                  href="https://arbiscan.io/address/0x27800A3453e2583953b50Fbd7F9Dd4d220f7e594"
                  target="blank"
                >
                  <img src={arbiLogo} width={22} className="object-cover" />
                </a>
              </div>
              <div className="px-2 flex">
                <a
                  href="https://opensea.io/collection/xdragon-hybrid-nft"
                  target="blank"
                >
                  <img src={openseaLogo} width={22} className="object-cover" />
                </a>
              </div>
              <div>
                <MenuDropdown />
              </div>
              <div className="px-2 flex">
                <a href="https://docs.dragoneyes.xyz" target="blank">
                  Docs
                </a>
              </div>
              <div className="px-2 flex text-white bg-[#1E3557] py-1.5 px-4 lg:py-2.5 lg:px-4 rounded-xl">
                <a
                  href="https://app.uniswap.org/swap?exactField=input&exactAmount=10&outputCurrency=0x27800A3453e2583953b50Fbd7F9Dd4d220f7e594&chain=arbitrum"
                  target="blank"
                  className="flex gap-2 items-center"
                >
                  <img
                    src={uniswapLogo}
                    width={22}
                    alt="uniswap"
                    className="object-cover"
                  />
                  <span>Buy XDRAGON</span>
                </a>
              </div>
              {walletAddress && correctChain ? (
                <div
                  style={{ position: "relative" }}
                  className="items-center text-center relative"
                >
                  <div
                    onClick={() => {
                      if (ethconnected) setTransferModalOpen(true);
                    }}
                    className="cursor-pointer max-h-48 lg:flex overflow-hidden transition-max-h bg-[#1E3557] text-white py-1.5 px-4 lg:py-2.5 lg:px-4 rounded-lg"
                  >
                    <img src={wallet_icon} className="w-[20px] mr-2"></img>
                    {truncateString(acc_.address, 8)}
                  </div>
                </div>
              ) : (
                <></>
              )}
              {ethconnected && correctChain ? (
                <></>
              ) : (
                <>
                  <ConnectButton /> <ChainGuard />
                </>
              )}
            </div>
          ) : (
            <div className="flex items-center gap-3 font-semibold right-0">
              <Button
                type="text"
                className="toogle-collapse-button"
                icon={
                  toggleMobileMenu ? (
                    <MenuUnfoldOutlined />
                  ) : (
                    <MenuFoldOutlined />
                  )
                }
                onClick={() => collapse()}
                style={{
                  fontSize: "16px",
                  width: 64,
                  height: 64,
                  color: "white",
                }}
              />
            </div>
          )}
        </div>
      </div>

      {toggleMobileMenu && isMobile ? (
        <div className="h-[100vh] text-[#1E3557] bg-warm-white bg-opacity-90 z-50 p-5 text-center justify-center transition duration-300 ease-in-out">
          <ChainGuard />
          <div className="grid w-full items-center text-center gap-3 font-semibold text-[#1E3557]">
            {walletAddress && correctChain ? (
              <>
                {/*<a className="py-1 px-2 lg:py-2.5 lg:px-4" href="/migrate">
                    Migration
                  </a>*/}
              </>
            ) : (
              <></>
            )}
            {walletAddress && correctChain ? <></> : <></>}
            <div className="w-full text-center flex flex-wrap items-center justify-center z-9999">
              <div className="w-2/6 flex justify-center py-3">
                <a
                  href="https://x.com/icdragoneyes"
                  target="blank"
                  className="py-1 px-2"
                >
                  <FaXTwitter className="h-6 w-6" />
                </a>
              </div>
              <div className="w-2/6 flex justify-center py-3">
                <a
                  href="https://discord.gg/2G2XfVgJ"
                  target="blank"
                  className="py-1 px-2"
                >
                  <FaDiscord className="h-6 w-6" />
                </a>
              </div>
              <div className="w-2/6 flex justify-center py-3">
                <a
                  href="https://arbiscan.io/address/0x27800A3453e2583953b50Fbd7F9Dd4d220f7e594"
                  target="blank"
                >
                  <img
                    src={arbiLogo}
                    width={25}
                    className="object-cover"
                    alt="arbiscan"
                  />
                </a>
              </div>
              <div className="w-2/6 flex justify-center py-3">
                <a
                  href="https://opensea.io/collection/xdragon-hybrid-nft"
                  target="blank"
                >
                  <img
                    src={openseaLogo}
                    width={25}
                    className="object-cover"
                    alt="arbiscan"
                  />
                </a>
              </div>
              <div className="w-1/2 flex justify-center py-3 text-lg">
                <a href="https://arb.dragoneyes.xyz" target="blank">
                  Arbitrum Play
                </a>
              </div>
              <div className="w-2/4 flex justify-center py-3 text-lg">
                <a href="https://icp.dragoneyes.xyz" target="blank">
                  ICP Play
                </a>
              </div>
              <div className="w-2/4 flex justify-center py-3 text-lg">
                <a href="https://docs.dragoneyes.xyz" target="blank">
                  Docs
                </a>
              </div>
              <div className="w-1/2 flex py-3 text-lg">
                <a
                  href="https://app.uniswap.org/swap?exactField=input&exactAmount=10&outputCurrency=0x27800A3453e2583953b50Fbd7F9Dd4d220f7e594&chain=arbitrum"
                  target="blank"
                  className="flex items-center gap-1"
                >
                  <img
                    src={uniswapLogo}
                    width={25}
                    alt="uniswap"
                    className="object-cover"
                  />
                  <span>Buy XDRAGON</span>
                </a>
              </div>
            </div>
            <div className="w-full flex items-center text-center justify-center ">
              {ethconnected && signature && correctChain ? (
                <div
                  onClick={() => {
                    if (ethconnected) setTransferModalOpen(true);
                  }}
                  className="w-full text-center justify-center flex items-center"
                >
                  <div className="cursor-pointer flex w-[100px]  justify-center items-center text-center  bg-[#1E3557] text-white  py-2 rounded-lg">
                    <img src={wallet_icon} className="w-[20px] mx-2"></img>
                    <div>Wallet</div>
                  </div>
                </div>
              ) : (
                <div className="w-full items-center text-center justify-center ">
                  <CustomConnectSmall />
                </div>
              )}
            </div>
            <div className="grid text-center items-center justify-center mt-10">
              Dragon Eyes @ 2024
            </div>{" "}
            <div className="w-full items-center justify-center text-center flex">
              <a href="https://dragoneyes.xyz" target="blank">
                <img src={loka_icon} className="w-[30px]  "></img>
              </a>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </nav>
  );
}
