import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppProvider";
import { actorCreation, getUserPrincipal } from "../service/icdragoncanister";
import { eyesCreation } from "../service/eyesledgercanister";
import { Principal } from "@dfinity/principal";
const Migration = () => {
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const { loginInstance, walletAddress, setEyesBalance, eyesBalance } =
    useContext(AppContext);
  const [eyesCanister, setEyesCanister] = useState(false);
  const [oldKey, setOldKey] = useState(false);
  const [oldW, setOldW] = useState(false);
  const [oldB, setOldB] = useState(false);
  const [oldFB, setOldFB] = useState(false);

  async function handleConnect() {
    //setLoading(true);
    try {
      const { privKey } = await loginInstance.login({
        loginProvider: "google",
        redirectUrl: `${window.origin}`,
      });

      if (!privKey) {
        throw new Error("failed login");
      }
      var principalString_ = getUserPrincipal(privKey).toString();
      setOldW(principalString_);
      setOldKey(privKey);
      var eyesOld_ = eyesCreation(privKey);
      setEyesCanister(eyesOld_);
      var accOld = {
        owner: Principal.fromText(principalString_),
        subaccount: [],
      };
      var eyesBalance_ = await eyesOld_.icrc1_balance_of(accOld);
      setOldB(Number(eyesBalance_));
      setOldFB(parseFloat(Number(eyesBalance_) / 100000000).toLocaleString());
      setStep(2);
      //setLoading(false);
    } catch (error) {
      //setLoading(false);
      setStep(1);
    }
  }

  async function handleMigrate() {
    setLoading(true);
    try {
      var accNow = {
        owner: Principal.fromText(walletAddress),
        subaccount: [],
      };

      var eyesOld_ = eyesCreation(oldKey);

      let transferEyesFromOldWallet = {
        to: accNow,
        fee: [],
        memo: [],
        from_subaccount: [],
        created_at_time: [],
        amount: oldB,
      };
      if (Number(oldB) > 0)
        var transferResult_ = await eyesOld_.icrc1_transfer(
          transferEyesFromOldWallet
        );

      var eyesBalance_ = await eyesOld_.icrc1_balance_of(accNow);
      setEyesBalance(Number(eyesBalance_));

      setLoading(false);
      setStep(1);
    } catch (error) {
      console.log(error, "<<<<<<<<err");
      setLoading(false);
      setStep(1);
    }
  }
  return (
    <div className="w-full max-w-md transform overflow-hidden p-10 text-[#1E3557] font-passion rounded-b-2xl min-h-96 bg-warm-white mt-0 text-center align-middle shadow-xl transition-all relative">
      <div className="text-lg lg:text-2xl">
        Migrate your $EYES to mint XDRAGON in two easy steps. Let’s get started!
      </div>
      <div className="grid w-full p-4 pt-8">
        <div className="text-base lg:text-xl text-left">
          1. Connect to your ICP wallet
        </div>
        <div>
          {step == 1 ? (
            <button
              onClick={() => handleConnect()}
              className="w-full text-center text-lg items-center justify-center  lg:flex lg:text-xl px-6 py-3 leading-none font-passion text-warm-white rounded-lg bg-bright-red "
            >
              Connect Using Google
            </button>
          ) : (
            <div className="text-[#0753c4] text-xl lg:text-2xl p-2">
              Connected!
            </div>
          )}
        </div>
      </div>
      <div className="grid w-full p-4">
        {step == 1 ? (
          <div className="text-base lg:text-xl text-left text-gray-400">
            2. Migrate Eyes to your Current Wallet
          </div>
        ) : step == 2 ? (
          <div className="grid w-full">
            <div className="text-base lg:text-xl text-left text-[#1E3557]">
              2. Migrate Eyes to your Current Wallet
            </div>
            <div className="grid p-2 rounded-2xl bg-[#d2c8a3] my-2 ">
              <div className="text-base lg:text-lg text-[#0753c4]">
                found :{" "}
              </div>
              <div className="text-lg p-2 lg:text-3xl">{oldFB} $EYES</div>
              <div className="text-base lg:text-xl">
                current wallet :{" "}
                {parseFloat(Number(eyesBalance) / 100000000).toLocaleString()}
              </div>
            </div>
            <button
              onClick={() => handleMigrate()}
              className="w-full text-center text-lg items-center justify-center  lg:flex lg:text-xl px-6 py-3 leading-none font-passion text-warm-white rounded-lg bg-bright-red "
            >
              {loading ? "Migrating $EYES... " : "Migrate"}
            </button>
          </div>
        ) : (
          <div className="grid">
            <div>Congrats</div>
            <div>Button</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Migration;
