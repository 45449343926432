import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

export default function PlayMenuDropdown() {
  const hanldeSelect = (key) => {
    switch (key) {
      case "icp":
        window.open(
          "https://icp.dragoneyes.xyz",
          "_blank",
          "noopener,noreferrer"
        );
        break;
      case "arbitrum":
        window.open(
          "https://arb.dragoneyes.xyz",
          "_blank",
          "noopener,noreferrer"
        );
        break;

      default:
        break;
    }
  };

  return (
    <div className=" text-right">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex w-full justify-center  p-2  text-[#1E3557] text-base">
            Play
            <ChevronDownIcon
              className="-mr-1 ml-2 h-5 mt-1 w-5 text-[#1E3557]"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute z-50 right-0 mt-2 w-28 origin-top-right divide-y divide-gray-100 rounded-md bg-slate-200/80 shadow-lg ring-1 ring-black/5 focus:outline-none">
            <div className="px-1 py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active
                        ? "bg-[#1E3557] text-white text-sm "
                        : "text-gray-900"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    onClick={() => hanldeSelect("icp")}
                  >
                    ICP
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active
                        ? "bg-[#1E3557] text-white text-sm"
                        : "text-gray-900"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    onClick={() => hanldeSelect("arbitrum")}
                  >
                    Arbitrum
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
