import { useAtom } from "jotai";
import { useDisconnect } from "wagmi";
import { isTransferModalOpenAtom } from "../store/modal";
import { Dialog, Transition } from "@headlessui/react";
import { useSendTransaction, usePrepareTransactionRequest } from "wagmi";
import React, { useContext, useEffect, useState } from "react";
import { Fragment } from "react";
import { useAccount } from "wagmi";
import { useTranslation } from "react-i18next";
import Icon, { PoweroffOutlined } from "@ant-design/icons";

import { AppContext } from "../context/AppProvider";

export default function WalletModal() {
  const [isTransferModalOpen, setModalOpen] = useAtom(isTransferModalOpenAtom);
  const { t } = useTranslation();

  useSendTransaction();
  const {
    setUserData,
    setCanisterActor,
    setWalletAddress,
    setToggleMobileMenu,
    eyesBalance,
    setEthconnected,
    walletAddress,
  } = useContext(AppContext);

  const { disconnect } = useDisconnect();
  const acc_ = useAccount();

  function truncateString(str, num) {
    if (!str) return "";
    if (str.length <= num) {
      return str;
    }
    const frontChars = Math.ceil(num / 2);
    const backChars = Math.floor(num / 2);
    return (
      str.substr(0, frontChars) + "..." + str.substr(str.length - backChars)
    );
  }

  function copyToClipboardE() {
    const textToCopy = acc_.address;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        alert("Wallet address copied to clipboard");
      })
      .catch((err) => {
        console.error("Error in copying text: ", err);
      });
  }

  function copyToClipboardW() {
    const textToCopy = walletAddress;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        alert("Wallet address copied to clipboard");
      })
      .catch((err) => {
        console.error("Error in copying text: ", err);
      });
  }

  const closeModal = () => {
    setModalOpen(false);
  };

  const [showModal, setShowModal] = useState(false);

  const handleLogout = async () => {
    disconnect();
    setEthconnected(false);
    setCanisterActor();
    setWalletAddress(false);
    setToggleMobileMenu(false);
    setShowModal(false);
    closeModal();
  };

  return (
    <Transition appear show={isTransferModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50 w-full" onClose={closeModal}>
        {/* Overlay */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/35 w-screen" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto w-screen">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {/* Main modal */}
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-warm-white p-6 text-left align-middle shadow-xl transition-all relative">
                <Dialog.Title
                  as="h3"
                  className="text-xl lg:text-2xl font-passion leading-6 text-gray-900 items-center text-center"
                >
                  Wallet
                </Dialog.Title>
                <div className="text-center grid w-full lg:text-xl text-sm">
                  <div className="text-sm text-dark-blue">
                    <div className="w-full grid  bg-dashboard-blue p-4 border bg-dark-blue border-[#1E3557] bg-opacity-90 rounded-2xl my-5 ">
                      <div className="text-center grid w-full lg:text-lg font-passion text-sm">
                        <div
                          className="flex w-full text-center justify-center items-center cursor-pointer text-warm-white"
                          onClick={() => {
                            copyToClipboardE();
                          }}
                        >
                          ARB : {truncateString(acc_.address, 12)}
                          <span className="cursor-pointer px-2">📋</span>
                        </div>
                        <div
                          className="flex w-full text-center justify-center items-center cursor-pointer text-warm-white"
                          onClick={() => {
                            copyToClipboardW();
                          }}
                        >
                          ICP : {truncateString(walletAddress, 12)}
                          <span className="cursor-pointer px-2">📋</span>
                        </div>

                        {/*<div
                          className="flex w-full text-center justify-center items-center cursor-pointer text-warm-white"
                          onClick={() => {
                            showPrivKey();
                          }}
                        >
                          <span className="cursor-pointer px-2 text-bright-red">
                            export wallet
                          </span>
                        </div> */}
                      </div>
                    </div>
                  </div>

                  <div className="font-passion text-2xl">
                    👀
                    {parseFloat(
                      Number(eyesBalance) / 100000000
                    ).toLocaleString()}{" "}
                    <span className=" text-red-600">EYES</span>
                  </div>
                </div>

                <div className="mt-2">
                  <div className="text-sm text-gray-500">
                    <div className="w-full grid  bg-dashboard-blue p-4  rounded-2xl my-5 ">
                      <div className="flex flex-col items-center justify-center text-center w-full p-0 ">
                        <button
                          onClick={handleLogout}
                          className="cursor-pointer text-2xl  lg:text-[20px] px-6 py-3 leading-none font-passion text-warm-white rounded-lg bg-[#EE5151]"
                        >
                          {"Disconnect"}
                          <PoweroffOutlined />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-4"></div>

                {/* Close button */}
                <button
                  type="button"
                  className="absolute top-6 right-6 rounded-full"
                  onClick={closeModal}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="lucide lucide-x"
                  >
                    <path d="M18 6 6 18" />
                    <path d="m6 6 12 12" />
                  </svg>
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
