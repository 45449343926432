import { useDrop } from "react-dnd";

const DropArea = ({ selectedNft, onSelectNft, isMurning = false }) => {
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: "box",
    drop: () => ({ name: "Dustbin" }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const isActive = canDrop && isOver;

  return (
    <div
      ref={drop}
      className={`w-full h-32 bg-white rounded-lg flex gap-2 p-3 ${
        isActive ? "border-2 border-[#1E3557]" : ""
      }`}
    >
      {selectedNft.map((nft) => {
        if (nft.isSelected) {
          return (
            <div key={nft.id} className={`${isMurning? 'animate animate-pulse ':'' } relative w-16 h-16`}>
              <div
                onClick={() => onSelectNft(nft.id, !nft.isSelected)}
                className={`${isMurning? 'bg-red-500 bg-opacity-50':''} absolute inset-0 bg-black bg-opacity-0 flex justify-center items-center text-white text-xs cursor-pointer rounded-md hover:bg-opacity-50`}
              >
                <span className="opacity-0 hover:opacity-100">Remove</span>
              </div>
              <img
                src={nft.src}
                alt={nft.alt}
                className="w-full h-full object-cover rounded-md"
              />
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

export default DropArea;
