import React, { useState, useContext, useEffect } from "react";
import ethIcon from "../../assets/eth-icon.png";
import { Principal } from "@dfinity/principal";
import { AppContext } from "../../context/AppProvider";

const loadingTypeMap = {
  referal: "referal",
  ticketFee: "ticketFee",
  rollPrice: "rollPrice",
  daily: "daily",
  eyes: "eyes",
};

const Claim = () => {
  const [mode, setMode] = useState("roll");
  const {
    canisterActor,
    gasFee,
    isGenesis,
    walletAddress,
    eyesLedger,
    setEyesBalance,
    setChangeAddress, //xdragon
  } = useContext(AppContext);

  const [loadingType, setLoadingType] = useState(null);
  const referalAmount = 0;
  const ticketFeeAmount = 0;
  const [rollPriceAmount, setRollPriceAmount] = useState(null);
  const [clm, setClm] = useState(null);
  const [dailyReward, setDailyReward] = useState(0);
  const [isDailyClaimable, setIsDailyClaimable] = useState(false);
  const [error, setError] = useState("");
  const [eyesError, setEyesError] = useState("");
  const [eyesReward, setEyesReward] = useState(0);

  const claimHistory = [
    {
      type: "Referal Fee",
      amount: "0.00",
      date: "2024-03-10",
      status: "Completed",
    },
    /*{
      type: "Referal Fee",
      amount: "100.00",
      date: "2024-03-10",
      status: "Completed",
    },
    {
      type: "Ticket Fee",
      amount: "50.00",
      date: "2024-03-09",
      status: "Pending",
    },
    {
      type: "Roll price",
      amount: "75.00",
      date: "2024-03-08",
      status: "Failed",
    }, */
  ];

  const handleClaimReferal = async () => {};

  const handleTicketFee = () => {
    setLoadingType(loadingTypeMap.ticketFee);

    // mocking claim ticket fee function
    setTimeout(() => {
      setLoadingType(null);
    }, 3000);
    console.log("handleTicketFee <<<");
  };

  const handleRollPrice = async () => {
    if (clm <= 0) return;
    console.log("claiming");
    setLoadingType(loadingTypeMap.rollPrice);
    try {
      var claimresult = await canisterActor.claimXDragonPot();
      var a = await getClaimables();
      console.log(claimresult);
    } catch (e) {
      console.log(e);
    }
    setLoadingType(null);
  };

  const getEyesBalance = async () => {
    try {
      // console.log("fetchin");
      var acc = {
        owner: Principal.fromText(walletAddress),
        subaccount: [],
      };
      var balance_ = await eyesLedger.icrc1_balance_of(acc);

      var n_ = Number(balance_) / 100000000;
      n_ = parseFloat(n_).toLocaleString();
      setEyesBalance(Number(balance_));
      //console.log(balance_, "<<<< new balance");
    } catch (e) {
      console.log(e);
    }
  };

  const handleClaimEyes = async () => {
    setEyesError("");
    if (eyesReward <= 0) return;
    //console.log("claiming eyes");
    setLoadingType(loadingTypeMap.eyes);
    try {
      var claimresult = await canisterActor.claimEyes();
      var a = await getClaimables();
      if (Number(claimresult) > 0) {
        setEyesError("Claim successful!");
        getEyesBalance();
        getClaimables();
      } else {
        setEyesError("Claim error");
      }
      console.log(claimresult, "<<< eyes claim");
    } catch (e) {
      console.log(e);
    }
    setLoadingType(null);
  };

  const handleDailyClaim = async () => {
    if (parseFloat(dailyReward) <= 0 || !isDailyClaimable) {
      console.log("error : below gas fee");
      setError("error : amount below gasfee threshold");

      return;
    }
    setError("");
    //console.log("claiming");
    setLoadingType(loadingTypeMap.daily);
    try {
      var claimresult = await canisterActor.claimDailyReward();
      var a = await getClaimables();
      console.log(claimresult);
      if (claimresult.error) {
        setError("Claim error : " + claimresult.error);
      }
      if (claimresult.success) {
        setError("Success! ");
      }
    } catch (e) {
      console.log(e);
    }
    setLoadingType(null);
  };

  const getClaimables = async () => {
    try {
      const res = await canisterActor.getAllClaimables();
      console.log(res, "<<<<<<<< claimables");
      setEyesReward(Number(res.eyes));
      setRollPriceAmount(
        parseFloat(Number(res.dragonpot) / 10 ** 18)
          .toFixed(5)
          .toLocaleString()
      );

      if (Number(res.daily) > gasFee) {
        setDailyReward(
          parseFloat((Number(res.daily) - gasFee) / 10 ** 18)
            .toFixed(5)
            .toLocaleString()
        );
        setIsDailyClaimable(true);
      } else {
        console.log("reward below gas fee " + gasFee / 10 ** 18);
        setDailyReward(parseFloat(0).toFixed(5).toLocaleString());
        console.log(dailyReward);
        setIsDailyClaimable(false);
      }
      setClm(Number(res.dragonpot));
    } catch (e) {}
  };

  useEffect(() => {
    getClaimables();
    //setError("ok<br/>lbb");
  }, [canisterActor]);

  return (
    <>
      <div className="w-full max-w-md transform overflow-hidden font-passion rounded-b-2xl min-h-96 bg-warm-white p-0 mt-0 align-middle shadow-xl transition-all relative">
        <div className="toogle-button  w-full flex justify-beetween pt-3 pr-5 pl-5 pb-2 gap-2">
          <button
            className={`w-1/2 p-1 h-[60px] rounded-lg border-2 border-[#D2C8A3] text-lg  font-passion ${
              mode === "roll"
                ? "bg-[#D2C8A3] text-[#1E3557]"
                : " text-warm-white bg-[#D6D3D1]"
            }`}
            onClick={() => {
              setMode("roll");
              //getRemainingTicket();
            }}
          >
            Dragon Pot
          </button>
          <button
            className={`w-1/2 p-1 h-[60px] rounded-lg border-2 border-[#D2C8A3]  text-base  text-[#1E3557] font-passion ${
              mode === "reward"
                ? "bg-[#D2C8A3] text-[#1E3557]"
                : " text-warm-white bg-[#D6D3D1]"
            }`}
            onClick={() => setMode("reward")}
          >
            Daily Reward
          </button>

          {isGenesis ? (
            <button
              className={`w-1/2 p-1 h-[60px] rounded-lg border-2 border-[#D2C8A3]  text-base  text-[#1E3557] font-passion ${
                mode === "ticket"
                  ? "bg-[#D2C8A3] text-[#1E3557]"
                  : " text-warm-white bg-[#D6D3D1]"
              }`}
              onClick={() => setMode("ticket")}
            >
              Ticket Fee
            </button>
          ) : (
            <></>
          )}
          <button
            className={`w-1/2 p-1 h-[60px] rounded-lg border-2 border-[#D2C8A3]  text-base  text-[#1E3557] font-passion ${
              mode === "eyes"
                ? "bg-[#D2C8A3] text-[#1E3557]"
                : " text-warm-white bg-[#D6D3D1]"
            }`}
            onClick={() => setMode("eyes")}
          >
            $EYES
          </button>
        </div>
        <div className="devider w-full border border-[#D6D2C5]" />
        {/* roll price claim section */}
        {mode === "roll" ? (
          <div className="p-10">
            <span className="w-full text-xl font-black text-[#1E3557] text-left font-passion pb-3">
              $ETH from Dragon Pot roll
            </span>
            <div className="reward-amount w-full flex justify-between items-center bg-[#DDD9CB] p-3 rounded-xl">
              <span className="text-3xl font-black text-[#1E3557] font-passion">
                {rollPriceAmount}
              </span>
              <div className="eth-icon flex justify-between p-2  rounded-lg gap-3 px-2">
                <span className="text-lg font-black text-[#1E3557] font-passion">
                  ETH
                </span>
                <img src={ethIcon} alt="wrong network" width={27} />
              </div>
            </div>
            <button
              className={`w-full p-3 ${
                clm <= 0
                  ? "bg-[#D6D3D1] cursor-not-allowed text-warm-white"
                  : " text-warm-white bg-[#EE5151]"
              }     rounded-xl text-3xl mt-5 font-black text-white font-passion`}
              onClick={handleRollPrice}
              disabled={loadingType || clm <= 0}
            >
              {loadingType === loadingTypeMap.rollPrice
                ? "Claiming..."
                : "Claim "}
            </button>
          </div>
        ) : (
          <></>
        )}

        {/* referal claim section */}
        {mode === "reward" ? (
          <div className="p-10">
            <span className="w-full text-xl font-black text-[#1E3557] text-left font-passion pb-3">
              $ETH from daily reward distribution
            </span>
            <div className="reward-amount w-full flex justify-between items-center bg-[#DDD9CB] p-3 rounded-xl">
              <span className="text-3xl font-black text-[#1E3557] font-passion">
                {dailyReward.toString()}
              </span>
              <div className="eth-icon flex justify-between p-2  rounded-lg gap-3 px-2">
                <span className="text-lg font-black text-[#1E3557] font-passion">
                  ETH
                </span>
                <img src={ethIcon} alt="wrong network" width={27} />
              </div>
            </div>
            <div className="text-bright-red pt-2"> {error} </div>
            <button
              className={`w-full p-3 ${
                !isDailyClaimable
                  ? "bg-[#D6D3D1] cursor-not-allowed text-warm-white"
                  : " text-warm-white bg-[#EE5151]"
              }     rounded-xl text-3xl mt-5 font-black text-white font-passion`}
              onClick={handleDailyClaim}
              disabled={loadingType}
            >
              {loadingType === loadingTypeMap.daily ? "Claiming..." : "Claim"}
            </button>
          </div>
        ) : (
          <></>
        )}

        {/* referal claim section */}
        {mode === "eyes" ? (
          <div className="p-10">
            <span className="w-full text-xl font-black text-[#1E3557] text-left font-passion pb-3">
              $EYES from referral action
            </span>
            <div className="reward-amount w-full flex justify-between items-center bg-[#DDD9CB] p-3 rounded-xl">
              <span className="text-3xl font-black text-[#1E3557] font-passion">
                {parseFloat(eyesReward / 100000000)
                  .toFixed(0)
                  .toString()}
              </span>
              <div className="eth-icon flex justify-between p-2  rounded-lg gap-3 px-2">
                <span className="text-lg font-black text-[#1E3557] font-passion">
                  👀EYES
                </span>
              </div>
            </div>
            <div className="text-bright-red pt-2"> {eyesError} </div>
            <button
              className={`w-full p-3 ${
                eyesReward <= 0
                  ? "bg-[#D6D3D1] cursor-not-allowed text-warm-white"
                  : " text-warm-white bg-[#EE5151]"
              }     rounded-xl text-3xl mt-5 font-black text-white font-passion`}
              onClick={handleClaimEyes}
              disabled={loadingType}
            >
              {loadingType === loadingTypeMap.eyes ? "Claiming..." : "Claim"}
            </button>
          </div>
        ) : (
          <></>
        )}

        {/* ticket fee claim section */}
        {isGenesis && mode === "ticket" ? (
          <div className="p-10">
            <span className="w-full text-xl font-black text-[#1E3557] text-left font-passion pb-3">
              $ETH from your ticket purchase referral
            </span>
            <div className="reward-amount w-full flex justify-between items-center bg-[#DDD9CB] p-3 rounded-xl">
              <span className="text-3xl font-black text-[#1E3557] font-passion">
                {ticketFeeAmount}
              </span>
              <div className="eth-icon flex justify-between p-2  rounded-lg gap-3 px-2">
                <span className="text-lg font-black text-[#1E3557] font-passion">
                  ETH
                </span>
                <img src={ethIcon} alt="wrong network" width={27} />
              </div>
            </div>
            <button
              className="w-full p-3  bg-stone-300 rounded-xl text-3xl mt-5  text-white font-passion"
              onClick={handleTicketFee}
              disabled={loadingType}
            >
              {loadingType === loadingTypeMap.ticketFee
                ? "Claiming..."
                : "Claim (coming soon)"}
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>

      {/* claim history section  */}
      <div className="w-full max-w-md transform overflow-hidden font-passion rounded-2xl  bg-warm-white p-0 mt-10  align-middle shadow-xl transition-all relative">
        <div className="p-10">
          <span className="w-full text-xl font-black text-[#1E3557] text-left font-passion pb-3">
            Claim history
          </span>
          <span className="text-[#1E3557]">Coming soon</span>
          {/*<table className="min-w-full">
            <thead className="bg-transparent text-[#1E3557]">
              <tr>
                <th className="text-left py-2 px-4 font-thin">Type</th>
                <th className="text-left py-2 px-4">Amount</th>
                <th className="text-left py-2 px-4">Date</th>
                <th className="text-left py-2 px-4">Status</th>
              </tr>
            </thead>
            <tbody>
              {claimHistory.map((transaction, index) => (
                <tr
                  key={index}
                  className={` text-[#1E3557] ${
                    index % 2 === 0 ? "bg-[#D9D9D9]" : "bg-transparent"
                  }`}
                >
                  <td className="text-left py-2 px-4">{transaction.type}</td>
                  <td className="font-thin py-2 px-4">{transaction.amount}</td>
                  <td className="font-thin py-2 px-4">{transaction.date}</td>
                  <td className="font-thin py-2 px-4">{transaction.status}</td>
                </tr>
              ))}
            </tbody>
                </table> */}
        </div>
      </div>
    </>
  );
};

export default Claim;
