import { useAtom } from "jotai";
import { useDisconnect } from "wagmi";
import { isTransferModalOpenAtom } from "../store/modal";
import { isGameMessageModalOpenAtom } from "../store/modal";
import { Dialog, Transition } from "@headlessui/react";
import { useSendTransaction, usePrepareTransactionRequest } from "wagmi";
import React, { useContext, useEffect, useState } from "react";
import { Fragment } from "react";
import { useAccount } from "wagmi";
import { useTranslation } from "react-i18next";
import Icon, { PoweroffOutlined } from "@ant-design/icons";
import confetti from "canvas-confetti";
import { AppContext } from "../context/AppProvider";

export default function GameMessageModal() {
  const [isGameModalOpen, setGameMessageModalOpen] = useAtom(
    isGameMessageModalOpenAtom
  );
  const { t } = useTranslation();

  useSendTransaction();
  const { gameRes, win, setWin } = useContext(AppContext);

  const closeModal = () => {
    setGameMessageModalOpen(false);
    setWin(false);
  };

  const [showModal, setShowModal] = useState(false);

  const launchConfetti = () => {
    let count = 0;
    const intervalId = setInterval(() => {
      if (count >= 5) {
        clearInterval(intervalId);
        return;
      }

      confetti({
        angle: 90,
        spread: 360,
        startVelocity: 40,
        elementCount: 200,
        dragFriction: 0.12,
        duration: 3000,
        stagger: 3,
        width: "10px",
        height: "10px",
        colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
      });

      count++;
    }, 500);
  };

  useEffect(() => {
    if (win == "YOU WIN!") launchConfetti();
    //setFirst(false);
  }, [win]);

  return (
    <Transition appear show={isGameModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50 w-full" onClose={closeModal}>
        {/* Overlay */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/35 w-screen" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto w-screen">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {/* Main modal */}
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-warm-white p-6 text-left align-middle shadow-xl transition-all relative">
                <Dialog.Title
                  as="h3"
                  className="text-xl lg:text-2xl font-passion leading-6 text-gray-900 items-center text-center"
                >
                  Dice Rolled!
                </Dialog.Title>
                <div className="text-center grid w-full lg:text-xl text-sm">
                  <div className="text-sm text-dark-blue"></div>

                  <div className=" font-passero text-2xl">
                    {win ? win.toString() : "You Lose"}
                  </div>
                </div>

                <div className="mt-4"></div>

                {/* Close button */}
                <button
                  type="button"
                  className="absolute top-6 right-6 rounded-full"
                  onClick={closeModal}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="lucide lucide-x"
                  >
                    <path d="M18 6 6 18" />
                    <path d="m6 6 12 12" />
                  </svg>
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
