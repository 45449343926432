import { useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";

export var User = {
  id: "",
  name: "",
  username: "",
  type: "local" | "twitter",
};

export function useTwitter() {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`http://www.localhost:3001/me`, {
        withCredentials: true,
      })
      .then((v) => {
        if (v.data) setData(v.data);
      })
      .catch(() => setError("Not Authenticated"))
      .finally(() => setLoading(false));
  }, []);

  return { error, data, loading };
}
