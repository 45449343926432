export const idlFactory = ({ IDL }) => {
  const TransferEyesResult = IDL.Variant({
    error: IDL.Text,
    success: IDL.Nat,
  });
  const Claimables = IDL.Record({
    dragonpot: IDL.Nat,
    ticket: IDL.Nat,
    eyes: IDL.Nat,
    daily: IDL.Nat,
  });
  const NFTMetadata = IDL.Record({
    id: IDL.Nat,
    armor: IDL.Text,
    background: IDL.Text,
    card: IDL.Text,
    eyes: IDL.Text,
    hair: IDL.Text,
    horn: IDL.Text,
    chest: IDL.Text,
    skin: IDL.Text,
    image: IDL.Text,
    elemental: IDL.Text,
    wings: IDL.Text,
  });
  const Referral = IDL.Record({
    time: IDL.Int,
    icpWallet: IDL.Text,
    ethWallet: IDL.Text,
  });
  const DailyDistribution = IDL.Record({
    nft: IDL.Nat,
    time: IDL.Int,
    holder: IDL.Nat,
    amount: IDL.Nat,
  });
  const UserDistribution = IDL.Record({
    day: IDL.Nat,
    nft: IDL.Nat,
    ethAddress: IDL.Text,
    time: IDL.Int,
    amount: IDL.Nat,
    icpAddress: IDL.Text,
  });
  const Mint = IDL.Record({
    xdragonPrice: IDL.Nat,
    ethAddress: IDL.Text,
    receipt: IDL.Text,
    validated: IDL.Bool,
    eyes: IDL.Nat,
    hash: IDL.Text,
    time: IDL.Int,
    xdragon: IDL.Nat,
    icpAddress: IDL.Text,
  });
  const TransferResult = IDL.Variant({
    reject: IDL.Text,
    error: IDL.Text,
    success: IDL.Text,
  });
  const HttpHeader = IDL.Record({ value: IDL.Text, name: IDL.Text });
  const HttpResponsePayload = IDL.Record({
    status: IDL.Nat,
    body: IDL.Vec(IDL.Nat8),
    headers: IDL.Vec(HttpHeader),
  });
  const TransformArgs = IDL.Record({
    context: IDL.Vec(IDL.Nat8),
    response: HttpResponsePayload,
  });
  const CanisterHttpResponsePayload = IDL.Record({
    status: IDL.Nat,
    body: IDL.Vec(IDL.Nat8),
    headers: IDL.Vec(HttpHeader),
  });
  const ICDragon = IDL.Service({
    addAdmin: IDL.Func([IDL.Text], [IDL.Nat], []),
    addE: IDL.Func([IDL.Nat, IDL.Text], [], []),
    addGenesisDistribution: IDL.Func([IDL.Text], [IDL.Nat], []),
    addPandoraDistribution: IDL.Func([IDL.Text, IDL.Nat], [IDL.Nat], []),
    addTicketCommission: IDL.Func([IDL.Text, IDL.Nat, IDL.Nat], [IDL.Nat], []),
    addTotalBurnt: IDL.Func([IDL.Nat], [IDL.Nat], []),
    addWhiteList: IDL.Func([IDL.Text], [IDL.Nat], []),
    batchAddPandora: IDL.Func([IDL.Text], [IDL.Nat], []),
    checkDR: IDL.Func([IDL.Text], [IDL.Opt(IDL.Nat)], ["query"]),
    checkDistURL: IDL.Func([], [IDL.Text], []),
    checkEyesReward: IDL.Func(
      [],
      [IDL.Variant({ none: IDL.Nat, distribute: IDL.Nat })],
      []
    ),
    checkGenesis: IDL.Func([IDL.Text], [IDL.Bool], []),
    checkRef: IDL.Func([IDL.Text], [IDL.Bool], ["query"]),
    claimDailyReward: IDL.Func(
      [],
      [
        IDL.Variant({
          reject: IDL.Text,
          none: IDL.Nat,
          error: IDL.Text,
          success: IDL.Text,
        }),
      ],
      []
    ),
    claimEyes: IDL.Func([], [IDL.Nat], []),
    claimTicketFee: IDL.Func([], [IDL.Nat], ["query"]),
    claimXDragonPot: IDL.Func(
      [],
      [
        IDL.Variant({
          reject: IDL.Text,
          none: IDL.Nat,
          error: IDL.Text,
          success: IDL.Text,
        }),
      ],
      []
    ),
    ddt: IDL.Func([IDL.Text, IDL.Nat], [IDL.Nat], []),
    distributeDailyReward: IDL.Func([IDL.Text, IDL.Text], [IDL.Nat], []),
    faucet: IDL.Func([IDL.Text, IDL.Nat], [TransferEyesResult], []),
    fetchSummoned: IDL.Func([], [IDL.Vec(IDL.Tuple(IDL.Nat, IDL.Text))], []),
    gNFT: IDL.Func([], [IDL.Vec(IDL.Tuple(IDL.Nat, IDL.Nat))], ["query"]),
    gNFTS: IDL.Func([], [IDL.Nat], ["query"]),
    gea: IDL.Func([IDL.Text], [IDL.Text], ["query"]),
    genOGAddr: IDL.Func([], [], []),
    getAllClaimables: IDL.Func([], [Claimables], ["query"]),
    getAllMetadata: IDL.Func(
      [],
      [IDL.Vec(IDL.Tuple(IDL.Nat, NFTMetadata))],
      ["query"]
    ),
    getAllMint: IDL.Func(
      [IDL.Text],
      [IDL.Variant({ no: IDL.Bool, yes: IDL.Bool })],
      ["query"]
    ),
    getAllReferrals: IDL.Func(
      [],
      [IDL.Vec(IDL.Tuple(IDL.Text, IDL.Vec(Referral)))],
      ["query"]
    ),
    getAllWhite: IDL.Func([], [IDL.Vec(IDL.Tuple(IDL.Text, IDL.Bool))], []),
    getAprBase: IDL.Func(
      [],
      [
        IDL.Record({
          apr: IDL.Float64,
          pot: IDL.Nat,
          total: IDL.Nat,
          last: IDL.Text,
          gasFee: IDL.Nat,
          lastUserMint: IDL.Int,
          lastXMint: IDL.Int,
          yesterday: IDL.Nat,
        }),
      ],
      []
    ),
    getC: IDL.Func([], [IDL.Nat], ["query"]),
    getClaimableReferralEyes: IDL.Func([IDL.Text], [IDL.Nat], ["query"]),
    getClaimables: IDL.Func(
      [],
      [IDL.Variant({ dragonpot: IDL.Nat })],
      ["query"]
    ),
    getCode: IDL.Func(
      [],
      [
        IDL.Variant({
          none: IDL.Nat,
          invitation: IDL.Text,
          genesis: IDL.Text,
        }),
      ],
      ["query"]
    ),
    getDH: IDL.Func(
      [],
      [IDL.Vec(IDL.Tuple(IDL.Nat, DailyDistribution))],
      ["query"]
    ),
    getDistributionByUser: IDL.Func(
      [IDL.Text],
      [IDL.Variant({ ok: IDL.Vec(UserDistribution), none: IDL.Nat })],
      ["query"]
    ),
    getEyesXDRAGON: IDL.Func([], [IDL.Nat], ["query"]),
    getInitialData: IDL.Func(
      [IDL.Text, IDL.Text],
      [
        IDL.Record({
          eyesReward: IDL.Variant({
            none: IDL.Nat,
            distribute: IDL.Nat,
          }),
          stats: IDL.Record({
            apr: IDL.Float64,
            pot: IDL.Nat,
            total: IDL.Nat,
            last: IDL.Text,
            gasFee: IDL.Nat,
            lastUserMint: IDL.Int,
            lastXMint: IDL.Int,
            yesterday: IDL.Nat,
          }),
          burnt: IDL.Nat,
          mintingData: IDL.Record({
            xEth: IDL.Nat,
            xPrice: IDL.Nat,
            lastUserMint: IDL.Int,
            lastMint: IDL.Int,
            coolDown: IDL.Int,
          }),
          initialMap: IDL.Variant({
            none: IDL.Nat,
            invitation: IDL.Text,
            genesis: IDL.Text,
          }),
          nextBurn: IDL.Int,
        }),
      ],
      []
    ),
    getLatestBintBlock: IDL.Func([], [IDL.Nat], ["query"]),
    getLatestMetadataMintBlock: IDL.Func([], [IDL.Nat], ["query"]),
    getLatestMetadataMurnBlock: IDL.Func([], [IDL.Nat], ["query"]),
    getMetadata: IDL.Func(
      [IDL.Nat],
      [IDL.Variant({ ok: IDL.Opt(NFTMetadata), none: IDL.Nat })],
      ["query"]
    ),
    getMintingData: IDL.Func(
      [],
      [
        IDL.Record({
          xEth: IDL.Nat,
          xPrice: IDL.Nat,
          lastUserMint: IDL.Int,
          burnt: IDL.Nat,
          lastMint: IDL.Int,
          coolDown: IDL.Int,
          nextBurn: IDL.Int,
        }),
      ],
      []
    ),
    getPotETHBalance: IDL.Func([], [IDL.Nat], ["query"]),
    getReferralsOf: IDL.Func(
      [IDL.Text],
      [IDL.Variant({ ok: IDL.Vec(Referral), none: IDL.Null })],
      ["query"]
    ),
    getRemainingRollTicket: IDL.Func([], [IDL.Nat], ["query"]),
    getTicketCommissions: IDL.Func(
      [],
      [IDL.Vec(IDL.Tuple(IDL.Text, IDL.Vec(Referral)))],
      ["query"]
    ),
    getTickets: IDL.Func([IDL.Text], [IDL.Opt(IDL.Nat)], ["query"]),
    getTotalDistribution: IDL.Func([], [IDL.Nat], ["query"]),
    getUnclaimedDaily: IDL.Func(
      [IDL.Text],
      [IDL.Variant({ ok: IDL.Nat, none: IDL.Nat })],
      ["query"]
    ),
    getUnclaimedDailyReward: IDL.Func(
      [],
      [IDL.Vec(IDL.Tuple(IDL.Nat, IDL.Nat))],
      ["query"]
    ),
    getUserMint: IDL.Func([IDL.Text], [IDL.Nat], ["query"]),
    getUserMintHash: IDL.Func([IDL.Text], [IDL.Vec(IDL.Text)], ["query"]),
    getce: IDL.Func(
      [IDL.Text],
      [
        IDL.Variant({
          none: IDL.Nat,
          invitation: IDL.Text,
          genesis: IDL.Text,
        }),
      ],
      []
    ),
    gia: IDL.Func([IDL.Text], [IDL.Text], ["query"]),
    initBatchMetadata: IDL.Func([IDL.Vec(IDL.Text)], [IDL.Nat], []),
    initialMap: IDL.Func(
      [IDL.Text, IDL.Text],
      [
        IDL.Variant({
          none: IDL.Nat,
          invitation: IDL.Text,
          genesis: IDL.Text,
        }),
      ],
      []
    ),
    initialMapCheck: IDL.Func(
      [IDL.Text, IDL.Text, IDL.Text],
      [
        IDL.Variant({
          none: IDL.Nat,
          invitation: IDL.Text,
          genesis: IDL.Text,
        }),
      ],
      []
    ),
    initiateDailyDistribution: IDL.Func([], [IDL.Text], []),
    isGenesisOnlyMint: IDL.Func([], [IDL.Bool], ["query"]),
    isMintEnabled: IDL.Func([], [IDL.Bool], ["query"]),
    isNotPaused: IDL.Func([], [IDL.Bool], ["query"]),
    isPandoraDistributed: IDL.Func(
      [IDL.Text],
      [
        IDL.Variant({
          distributed: IDL.Nat,
          notPandora: IDL.Nat,
          notDistributed: IDL.Nat,
        }),
      ],
      ["query"]
    ),
    isStillProcessing: IDL.Func([], [IDL.Nat], ["query"]),
    manualReMint: IDL.Func(
      [IDL.Text, IDL.Nat],
      [IDL.Variant({ error: IDL.Text, success: IDL.Nat })],
      []
    ),
    migrateAddr: IDL.Func(
      [IDL.Text],
      [IDL.Variant({ ok: IDL.Text, none: IDL.Text })],
      []
    ),
    migrateManual: IDL.Func(
      [IDL.Text, IDL.Text],
      [IDL.Variant({ ok: IDL.Text, none: IDL.Text })],
      []
    ),
    mintXDRAGON: IDL.Func(
      [IDL.Nat, IDL.Nat],
      [
        IDL.Variant({
          error: IDL.Text,
          success: IDL.Text,
          timeout: IDL.Text,
        }),
      ],
      []
    ),
    mints: IDL.Func([IDL.Text], [IDL.Opt(IDL.Vec(Mint))], []),
    pauseCanister: IDL.Func([IDL.Bool], [IDL.Bool], []),
    resumeTimer: IDL.Func([], [IDL.Nat], []),
    roll_dice: IDL.Func(
      [],
      [
        IDL.Variant({
          win: IDL.Nat,
          noroll: IDL.Nat,
          lose: IDL.Vec(IDL.Nat8),
          noticket: IDL.Nat,
        }),
      ],
      []
    ),
    setARBCanister: IDL.Func([IDL.Text], [IDL.Text], []),
    setDappsKey: IDL.Func([IDL.Text], [IDL.Text], []),
    setEthVault: IDL.Func([IDL.Text], [IDL.Text], []),
    setEyesXDRAGON: IDL.Func([IDL.Nat, IDL.Nat], [IDL.Nat], []),
    setGas: IDL.Func([IDL.Nat], [IDL.Nat], []),
    setGasReserve: IDL.Func([IDL.Nat], [IDL.Nat], []),
    setMint: IDL.Func([IDL.Bool], [IDL.Bool], []),
    setMintingAccount: IDL.Func([IDL.Text], [IDL.Text], []),
    setOnlyGenesis: IDL.Func([IDL.Bool], [], []),
    setProcessing: IDL.Func([], [IDL.Nat], []),
    setTwo: IDL.Func([], [IDL.Nat], []),
    startCoolingTimer: IDL.Func([], [IDL.Nat], []),
    startPhase2: IDL.Func([], [IDL.Nat], []),
    startTimer: IDL.Func([], [IDL.Nat], []),
    tNat: IDL.Func([IDL.Nat], [IDL.Nat], []),
    testBurn: IDL.Func([], [TransferResult], []),
    testLok: IDL.Func([], [IDL.Text], []),
    ticketBint: IDL.Func([IDL.Text, IDL.Nat], [IDL.Nat], []),
    ticketBintRequest: IDL.Func([], [IDL.Nat], []),
    transform: IDL.Func(
      [TransformArgs],
      [CanisterHttpResponsePayload],
      ["query"]
    ),
    updateMetadata: IDL.Func(
      [IDL.Text, IDL.Text, IDL.Nat, IDL.Nat],
      [IDL.Nat],
      []
    ),
    updateMintResult: IDL.Func([IDL.Text, IDL.Text], [IDL.Nat], []),
    updatePotETHBalance: IDL.Func([IDL.Nat, IDL.Nat], [IDL.Nat], []),
  });
  return ICDragon;
};
export const init = ({ IDL }) => {
  return [IDL.Record({ admin: IDL.Principal })];
};
