import React, { useEffect, useState } from "react";

import dice from "../../assets/dice.png";
import "./style.css";

const getRandomRoll = () => {
  const min = Math.ceil(1);
  const max = Math.floor(6);
  const dice1 = Math.floor(Math.random() * (max - min + 1)) + min;
  const dice2 = Math.floor(Math.random() * (max - min + 1)) + min;
  return [dice1, dice2];
};

const DiceRoll = ({ onRoll, result }) => {
  // Use state to manage dice rolls
  const [diceRolls, setDiceRolls] = useState([1, 2]);

  // Simulate the toggleClasses by determining if roll is odd or even
  const getRollClass = (roll) => (roll % 2 === 0 ? "even-roll" : "odd-roll");

  useEffect(() => {
    if (onRoll) {
      // Start rolling animation by updating dice rolls randomly
      const interval = setInterval(() => {
        setDiceRolls(getRandomRoll());
      }, 100); // Change the interval as needed

      return () => clearInterval(interval);
    } else {
      // When onRoll is false, set the final result
      setDiceRolls(result);
    }
  }, [onRoll, result]);

  // Helper function to generate dice sides based on the current roll
  const renderDiceSides = (roll) => {
    return Array.from({ length: 6 }, (_, i) => (
      <li className="die-item" data-side={i + 1} key={i}>
        {Array.from({ length: i + 1 }).map((_, j) => (
          <span className="dot" key={j}></span>
        ))}
      </li>
    ));
  };

  return (
    <div className="dice w-full">
      {diceRolls.map((roll, index) => (
        <ol
          className={`die-list ${getRollClass(roll)}`}
          data-roll={roll}
          key={index}
          id={`die-${index + 1}`}
        >
          {renderDiceSides(roll)}
        </ol>
      ))}
    </div>
  );
};

export default DiceRoll;
